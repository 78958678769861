import classNames from "classnames";
import i18next from "i18next";
import cookies from "js-cookie";
import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons/lib";
import InjectIntl from "react-intl-inject";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

import logoNavbar from "../assets/seezittLogoDarkNavbar.png";
import {
  Logo,
  MobileIcon,
  Nav,
  NavbarContainer,
  NavBtn,
  NavBtnLink,
  NavItem,
  NavLogo,
  NavMenu,
} from "./Navbar/NavbarElements";

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    dir: "rtl",
    country_code: "sa",
  },
  {
    code: "fr",
    name: "French",
    country_code: "fr",
  },
];

const Navbarr = (props) => {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t, i18n } = useTranslation();
  console.log("props", props);

  //   const [faqsNav, setFaqsNav] = useState(false);
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  useEffect(() => {
    console.log("SeezItt");
    document.body.dir = (currentLanguage && currentLanguage.dir) || "ltr";
    document.title = t("Seezitt");
  }, [currentLanguage, t]);

  const toggleHome = () => {
    scroll.scrollToTop();
  };
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "auto",
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    });
  };

  return (
    <InjectIntl>
      {({ intl }) => (
        <>
          <IconContext.Provider value={{ color: "#fff" }}>
            <Nav scrollNav={scrollNav} faqsNav={props.faqsNav}>
              <NavbarContainer
                style={{
                  fontFamily: i18n.language === "ar" ? "Tajawal" : "Lexend",
                }}
              >
                <NavLogo
                  to="/"
                  onClick={toggleHome}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Logo src={logoNavbar} />
                </NavLogo>
                <MobileIcon onClick={props.toggle}>
                  <svg
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 1.25C0 1.05109 0.0790178 0.860322 0.21967 0.71967C0.360322 0.579018 0.551088 0.5 0.75 0.5H12.75C12.9489 0.5 13.1397 0.579018 13.2803 0.71967C13.421 0.860322 13.5 1.05109 13.5 1.25C13.5 1.44891 13.421 1.63968 13.2803 1.78033C13.1397 1.92098 12.9489 2 12.75 2H0.75C0.551088 2 0.360322 1.92098 0.21967 1.78033C0.0790178 1.63968 0 1.44891 0 1.25ZM0 13.25C0 13.0511 0.0790178 12.8603 0.21967 12.7197C0.360322 12.579 0.551088 12.5 0.75 12.5H11.25C11.4489 12.5 11.6397 12.579 11.7803 12.7197C11.921 12.8603 12 13.0511 12 13.25C12 13.4489 11.921 13.6397 11.7803 13.7803C11.6397 13.921 11.4489 14 11.25 14H0.75C0.551088 14 0.360322 13.921 0.21967 13.7803C0.0790178 13.6397 0 13.4489 0 13.25ZM0.75 6.5C0.551088 6.5 0.360322 6.57902 0.21967 6.71967C0.0790178 6.86032 0 7.05109 0 7.25C0 7.44891 0.0790178 7.63968 0.21967 7.78033C0.360322 7.92098 0.551088 8 0.75 8H17.25C17.4489 8 17.6397 7.92098 17.7803 7.78033C17.921 7.63968 18 7.44891 18 7.25C18 7.05109 17.921 6.86032 17.7803 6.71967C17.6397 6.57902 17.4489 6.5 17.25 6.5H0.75Z"
                      fill="white"
                    />
                  </svg>
                </MobileIcon>
                <NavMenu>
                  <NavItem>
                    <Link
                      to="/#About"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                      className="nav-link"
                    >
                      {" "}
                      {t("home.about")}
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      to="/#Feature"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                      className="nav-link"
                    >
                      {" "}
                      {t("home.features")}
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      to="/#Discover"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                      className="nav-link"
                    >
                      {" "}
                      {t("home.discover")}
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      to="/faqs"
                      smooth={true}
                      spy={true}
                      exact="true"
                      offset={-80}
                      className="nav-link"
                    >
                      {/* {currentLanguageCode === ("en" || "fr")
                        ? "FAQs"
                        : "الأسئلة المتكررة"} */}
                      {" "}
                      {t("home.faqs")}
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      to="/#ContactUs"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                      className="nav-link"
                    >
                      {" "}
                      {t("home.contactUs")}
                    </Link>
                  </NavItem>
                </NavMenu>
                <NavBtn>
                  <div className="language-select">
                    <div className="d-flex justify-content-end align-items-center language-select-root">
                      <div className="dropdown">
                        <button
                          className="langSelector"
                          type="button"
                          id="dropdownDefaultButton"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {currentLanguage.name}
                          <span className="m-md-1">
                            <svg
                              width="7"
                              height="6"
                              viewBox="0 0 7 6"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.5 6L6.53109 0.75H0.468911L3.5 6Z"
                                fill="#D9D9D9"
                              />
                            </svg>
                          </span>
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownDefaultButton"
                        >
                          {languages.map(({ code, name, country_code }) => (
                            <li key={country_code}>
                              {/* <a
                               href="javascript:void(0)"
                                className={classNames("dropdown-item", {
                                  disabled: currentLanguageCode === code,
                                })}
                                onClick={() => {
                                  i18next.changeLanguage(code);
                                }}
                              > */}
                              <div
                                role="button"
                                tabIndex="0" // This makes the div focusable for accessibility
                                className={classNames("dropdown-item", {
                                  disabled: currentLanguageCode === code,
                                })}
                                onClick={() => {
                                  i18next.changeLanguage(code);
                                }}
                              >
                                {/* Your link content goes here */}

                                <span
                                  className={`flag-icon flag-icon-${country_code} `}
                                  style={{
                                    opacity:
                                      currentLanguageCode === code ? 0.5 : 1,
                                  }}
                                ></span>
                                {name}
                                {/* </a> */}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <NavBtnLink onClick={scrollToBottom}>
                    {" "}
                    {t("home.downloadApp")}
                  </NavBtnLink>
                </NavBtn>
              </NavbarContainer>
            </Nav>
          </IconContext.Provider>
        </>
      )}
    </InjectIntl>
  );
};

export default Navbarr;
