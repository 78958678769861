import { FaTimes } from 'react-icons/fa';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';
import styled from 'styled-components';

export const SidebarContainer = styled.aside`
	position: fixed;
	z-index: 999;
	width: 100%;
	height: 100%;
	background: #fff;
	display: grid;
	align-items: center;
	top: 0;
	left: 0;
	transition: 0.3s ease-in-out;
	display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
	top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};

	@media screen and (max-width: 480px) {
		width: auto;
		height: auto;
		left: -26px;
		border-radius: 15px;
		padding: 10px 0px 0px 35px;
		top: 37px;
		background: #fff;
	}

	@media screen and (max-width: 768px) {
		height: 100vh;
		border-radius: 0px;
		top: 0px;
		background: rgba(0, 0, 0, 0.79);
		color: white;
		padding: 0px 67px;
	}
`;

export const CloseIcon = styled(FaTimes)`
	position: absolute;
	top: 80px;
	right: 80px;
`;
export const Icon = styled.div`
	position: absolute;
	top: 20px;
	right: 20px;
	background: transparent;
	cursor: pointer;
	outline: none;
`;
export const SidebarWrapper = styled.div`
	color: rgb(255, 255, 255);
	display: flex;
	justify-content: start;
	width: 100%;
	height: 100%;
	align-items: center;
	margin-left: -20px;
`;
export const SidebarMenu = styled.ul`
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 100%;

`;
// links of sidebar
export const SidebarLink = styled(LinkS)`
	display: flex;
	align-items: center;
	justify-content: start;
	font-size: 1.5rem;
	text-decoration: none;
	list-style: none;
	transition: 02s ease-in-out;
	text-decoration: none;
	color: #fff;
	cursor: pointer;
	margin: 0px;
	padding: 5px 10px;
	font-family: 'Lexend';
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 150%;
	font-size: 18px;
    width: 100%;
    color: rgb(255, 255, 255);
    margin-bottom: 32px;

	&:hover {
		color: #01bf71;
		transition: 0.2s ease-in-out;
	}
	&:active {
		color: #01bf71;
	}
`;
export const SideBtnWrap = styled.div`
	display: flex;
	justify-content: start;
`;
// download button on small device menu open
export const SidebarRoute = styled(LinkR)`
	border-radius: 50px;
	background: #5d41de;
	white-space: nowrap;
	padding: 16px 64px;
	color: #fff;
	font-size: 14px;
	outline: none;
	border: none;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	text-decoration: none;

	&:hover {
		transition: all 0.2s ease-in-out;
		background: #fff;
		color: #000;
	}

    display: flex;
    /* padding: 8px 15px; */
    font-size: 18px;
    margin-top: 1px;
    width: 100%;
    height: 36px;
    /* margin-bottom: 32px; */
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    width: 330px;
    color: #FFF;
    text-align: center;
    font-family: Lexend;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
`;

export const SidebarLinks = styled(LinkR)`
	display: flex;
	align-items: center;
	justify-content: start;
	font-size: 1.5rem;
	text-decoration: none;
	list-style: none;
	transition: 02s ease-in-out;
	text-decoration: none;
	color: #fff;
	cursor: pointer;
	margin: 0px;
	padding: 5px 10px;
	font-family: Lexend;
	font-size: 30px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

	&:hover {
		color: #01bf71;
		transition: 0.2s ease-in-out;
	}
	&:active {
		color: #01bf71;
	}
	@media screen and (max-width: 768px) {
		font-size: 18px;
		width: 100%;
		margin-bottom: 32px;
	}
`;
