import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FaCircle } from 'react-icons/fa';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../SideBar';
import Footerr from '../footerr/Footer';

import './Feature.css';

import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

const languages = [
	{
		code: 'en',
		name: 'English',
		country_code: 'gb',
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl',
		country_code: 'sa',
	},
	{
		code: "fr",
		name: "French",
		country_code: "fr",
	},
];
function Features(props) {
	const currentLanguageCode = cookies.get('i18next') || 'en';
	const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
	const { t, i18n } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => {
		setIsOpen(!isOpen);
	};
	useEffect(() => {
		console.log('SeezItt');
		document.body.dir = (currentLanguage && currentLanguage.dir) || "ltr";
		document.title = t('Seezitt');
	}, [currentLanguage, t]);
	return (
		<>
			<div style={{ fontFamily: i18n.language === 'ar' ? 'Tajawal' : 'Lexend' }}>
				{window.location.pathname === '/features' && <Sidebar isOpen={isOpen} toggle={toggle} /> && (
					<Navbar toggle={toggle} setLanguage={props.setLanguage} language={props.language} />
				)}
				<div id='Feature' style={{ direction: props.language === 'ar' || props.language === 'ur' ? 'rtl' : 'ltr' }}>
					{currentLanguageCode === 'ar' ?
						(
							<div className={'features-container'}>
								<div className='container features-section '>
									<div className='row'>
										<div className='featuresTitle'>{t('home.featuresTitle')}</div>
										<div className='indicatorWrapper' style={{ marginBottom: '-15px' }}>
											<img src='/img/Active Indicator.png' className='indicator' alt='indicator' />
										</div>
									</div>

									<Container className=''>
										<Row xs={1} lg={2} className='justify-content-center align-items-center' style={{ marginRight: '5px' }}>
											<Col lg={5} md={5} xs={6} className='paragraphWrapper' >
												<p className='paragraphTitleAr'>
													<h2 className='featuresheaddingAr'>
														<span style={{ marginLeft: '10px' }}>
															<FaCircle size={20} color='#42ACD9' className='FaCircle' />
														</span>
														{t('home.photoLayoutheadder')}
													</h2>
												</p>

												<p className='about-containtAr' >
													<p className={'featuresubheaddingAr'}>{t('home.videoEnhancementsub')}</p>
												</p>
											</Col>
											<Col lg={5} md={5} xs={6} className='text-center' style={{ marginRight: '0px' }}>
												<div className='about-img' style={{ minHeight: '135px', minWidth: '203px', marginRight: '20px', alignContent: 'flex-end' }}>
													<img src='/img/Group 5196.png' alt='' />
												</div>
											</Col>
										</Row>
									</Container>

									<Container className='' style={{ display: 'flex' }}>
										<Row xs={1} lg={2} className='justify-content-center align-items-center' style={{ marginRight: '5px' }}>
											<Col lg={5} md={5} xs={6} className='text-center' style={{ marginRight: '0px' }}>
												<div className='about-img' style={{ minHeight: '135px', minWidth: '180px', marginRight: '20px' }}>
													<img src='/img/Group 5197.png' alt='' />
												</div>
											</Col>
											<Col lg={5} md={5} xs={6} className='px-2' style={{ marginRight: '-8px' }}>
												<p className='paragraphTitleAr'>
													<h2 className='featuresheaddingAr'>
														<span style={{ marginLeft: '10px' }}>
															<FaCircle size={20} color='#42ACD9' className='FaCircle' />
														</span>
														{t('home.homeFeedheadder')}
													</h2>
												</p>
												<p className='about-containtAr' >
													<p className='featuresubheaddingAr'>{t('home.homeFeedSub')}</p>
												</p>
											</Col>
										</Row>
									</Container>

									<Container className=''>
										<Row xs={1} lg={2} className='justify-content-center align-items-center' style={{ marginRight: '5px' }}>
											<Col lg={5} md={5} xs={6} className=' px-2'>
												<p className='paragraphTitleAr'>
													<h2 className='featuresheaddingAr'>
														<span style={{ marginLeft: '10px' }}>
															<FaCircle size={20} color='#42ACD9' className='FaCircle' />
														</span>
														{t('home.videoEnhancementhedder')}
													</h2>
												</p>

												<p className='about-containtAr' >
													<p className='featuresubheaddingAr'>{t('home.photoLayoutheaddersub')}</p>
												</p>
											</Col>
											<Col lg={5} md={5} xs={6} className='text-center' style={{ marginRight: '0px' }}>
												<div className='about-img' style={{ minHeight: '135px', minWidth: '203px', marginRight: '20px' }}>
													<img src='/img/Group 5198.png' alt='' />
												</div>
											</Col>

										</Row>
									</Container>

									<Container className='' style={{ display: 'flex' }}>
										<Row xs={1} lg={2} className='justify-content-center align-items-center' style={{ marginRight: '5px' }}>
											<Col lg={5} md={5} xs={6} className='text-center' style={{ marginRight: '0px' }}>
												<div className='about-img' style={{ minHeight: '135px', minWidth: '203px', marginRight: '20px' }}>
													<img src='/img/Group 5199.png' alt='' />
												</div>
											</Col>
											<Col lg={5} md={5} xs={6} className='px-2'>
												<p className='paragraphTitleAr'>
													<h2 className='featuresheaddingAr' style={{ minWidth: '152px' }}>
														<span style={{ marginLeft: '10px' }}>
															<FaCircle size={20} color='#42ACD9' className='FaCircle' />
														</span>
														{t('home.categorySelectionheadder')}
													</h2>
												</p>

												<p className='about-containtAr' >
													<p className='featuresubheaddingAr'>{t('home.categorySelectionsub')} </p>
												</p>
											</Col>
										</Row>
									</Container>

									<Container className='' style={{ marginBottom: -20 }}>
										<Row xs={1} lg={2} className='justify-content-center align-items-center' style={{ marginRight: '5px' }}>

											<Col lg={5} md={5} xs={6} className='px-2'>
												<p className='featuresTitleAr' style={{ marginBottom: '30px' }}>
													<h2 className='featuresheaddingAr'>
														<span style={{ marginLeft: '10px' }}>
															<FaCircle size={20} color='#42ACD9' className='FaCircle' />
														</span>
														{t('home.connectMemoriesheadder')}{' '}
													</h2>
												</p>

												<p className='about-containtAr' >
													<p className='featuresubheaddingAr'>{t('home.connectMemoriessub')}</p>
												</p>
											</Col>
											<Col lg={5} md={5} xs={6} className='text-center' style={{ marginRight: '0px' }}>
												<div className='about-img' style={{ minHeight: '135px', minWidth: '203px', marginRight: '20px' }}>
													<img src='/img/Feature 1 design.png' alt='' />
												</div>
											</Col>
										</Row>
									</Container>
								</div>
							</div>
						) : (
							<div className={'features-container'}>
								<div className='container features-section '>
									<div className='row'>
										<div className='featuresTitle'>{t('home.featuresTitle')}</div>
										<div className='indicatorWrapper'>
											<img src='/img/Active Indicator.png' className='indicator' alt='indicator' />
										</div>
									</div>

									<Container className=''>
										<Row xs={1} lg={2} className='justify-content-center align-items-center'>
											<Col lg={5} md={5} xs={6} className='text-center' style={{ marginRight: '-20px' }}>
												<div className='about-img' style={{ minHeight: '135px', minWidth: '203px', marginLeft: '-32px' }}>
													<img src='/img/Group 5196.png' alt='' />
												</div>
											</Col>
											<Col lg={5} md={5} xs={6} className='paragraphWrapper' >
												<p className='paragraphTitle'>
													<h2 className='featuresheadding'>
														<span className='feature1Span'>
															<FaCircle size={20} color='#42ACD9' className='FaCircle' />
														</span>
														{t('home.photoLayoutheadder')}
													</h2>
												</p>

												<p className='about-containt'>
													<p className={'featuresubheadding'} >{t('home.videoEnhancementsub')}</p>
												</p>
											</Col>
										</Row>
									</Container>

									<Container className=''>
										<Row xs={1} lg={2} className='justify-content-center align-items-center'>
											<Col lg={5} md={5} xs={6} className='px-2'>
												<p className='paragraphTitle text-end'>
													<h2 className='featuresheadding'>
														<span className='feature1Span'>
															<FaCircle size={20} color='#42ACD9' className='FaCircle' />
														</span>
														{t('home.homeFeedheadder')}
													</h2>
												</p>

												<p className='about-containt text-end'>
													<p className='featuresubheadding'>{t('home.homeFeedSub')}</p>
												</p>
											</Col>
											<Col lg={5} md={5} xs={6} className='text-center'>
												<div className='about-img' style={{ minHeight: '135px', minWidth: '180px', marginRight: '20px' }}>
													<img src='/img/Group 5197.png' alt='' />
												</div>
											</Col>
										</Row>
									</Container>

									<Container className=''>
										<Row xs={1} lg={2} className='justify-content-center align-items-center'>
											<Col lg={5} md={5} xs={6} className='text-center'>
												<div className='about-img' style={{ minHeight: '135px', minWidth: '203px', marginRight: '20px' }}>
													<img src='/img/Group 5198.png' alt='' />
												</div>
											</Col>
											<Col lg={5} md={5} xs={6} className=' px-2'>
												<p className='about-containt '>
													<h2 className='featuresheadding'>
														<span className='feature1Span'>
															<FaCircle size={20} color='#42ACD9' className='FaCircle' />
														</span>
														{t('home.videoEnhancementhedder')}
													</h2>
												</p>

												<p className='about-containt'>
													<p className='featuresubheadding'>{t('home.photoLayoutheaddersub')}</p>
												</p>
											</Col>
										</Row>
									</Container>

									<Container className=''>
										<Row xs={1} lg={2} className='justify-content-center align-items-center'>
											<Col lg={5} md={5} xs={6} className='px-2'>
												<p className='about-containt text-end '>
													<h2 className='featuresheadding' style={{ minWidth: '152px' }}>
														<span className='feature1Span'>
															<FaCircle size={20} color='#42ACD9' className='FaCircle' />
														</span>
														{t('home.categorySelectionheadder')}{' '}
													</h2>
												</p>

												<p className='about-containt text-end'>
													<p className='featuresubheadding' >{t('home.categorySelectionsub')} </p>
												</p>
											</Col>
											<Col lg={5} md={5} xs={6} className='text-center'>
												<div className='about-img' style={{ minHeight: '135px', minWidth: '203px', marginRight: '20px' }}>
													<img src='/img/Group 5199.png' alt='' />
												</div>
											</Col>
										</Row>
									</Container>

									<Container className='' style={{ marginBottom: -20 }}>
										<Row xs={1} lg={2} className='justify-content-center align-items-center'>
											<Col lg={5} md={5} xs={6} className='text-center'>
												<div className='about-img' style={{ minHeight: '135px', minWidth: '203px', marginRight: '20px' }}>
													<img src='/img/Feature 1 design.png' alt='' />
												</div>
											</Col>
											<Col lg={5} md={5} xs={6} className='px-2'>
												<p className='about-containt '>
													<h2 className='featuresheadding'>
														<span className='feature1Span'>
															<FaCircle size={20} color='#42ACD9' className='FaCircle' />
														</span>
														{t('home.connectMemoriesheadder')}{' '}
													</h2>
												</p>

												<p className='about-containt'>
													<p className='featuresubheadding'>{t('home.connectMemoriessub')} </p>
												</p>
											</Col>
										</Row>
									</Container>
								</div>
							</div>
						)}


					{window.location.pathname === '/features' && (
						<Footerr setLanguage={props.setLanguage} language={props.language} />
					)}
				</div>
			</div>


		</>
	);
}

export default Features;
