const translationsEn = {
  "home.about": "About2222",
  "home.contactUs": "Contact Us",
  test: "test awy",
  "home.faqs": "FAQs",
  "home.features": "Features",
  "home.discover": "Discover",
  "home.downloadApp": "Download App",
  "home.heroHead": "A platform to make someone happy everyday",
  "home.heroSubhead":
    "Create short videos to showcase your talents, connect with friends and smile",
  "home.title": "About Us",
  "home.aboutHeadder": "Unleash your creativity",
  "home.aboutSubheadder":
    "SeezItt provides a space to share your short videos for you to get your next big ideas, dinner recipes, party outfits and much more ",
  "home.aboutDownload": "Download Now",
  "home.featuresTitle": "Features",
  "home.videoEnhancementhedder": "Video Enhancement",
  "home.videoEnhancementsub":
    "Record, upload short videos and reels for 15 seconds with the easy to use free video editing tool to boost and captivate your audience",
  "home.homeFeedheadder": "Home Feed",
  "home.homeFeedSub":
    "Homepage where your feed creates content and enables you can engage with the community. Become a trend setter or a content creator",
  "home.photoLayoutheadder": "Photo and Video Grid Layout",
  "home.photoLayoutheaddersub":
    "Express yourself, imagine possibilities, dream opportunities. Bring your asthetic game to invigorate your creativity",
  "home.categorySelectionheadder": "Category Selection",
  "home.categorySelectionsub":
    "Follow your favourite topics, content is strictly curated to your relevant interest through our  trained machine learning",
  "home.connectMemoriesheadder": "Connect with memories",
  "home.connectMemoriessub":
    "Videos bring back memories, use the moment to reconnect or reunite with friends, family, or colleagues ",
  "home.discovertitle": "Discover",
  "home.ourGiftsKit": "Our Gifts Kit",
  "home.ourGiftsKitsub":
    "Support your favorite profiles in the community by sending them gifts.",
  "home.caesiumCoins": "Caesium Coins",
  "home.caesiumCoinssub":
    "Gain and gift real money by using our virtual cuurency, Caesium.",
  "home.standOut": "Stand Out",
  "home.standOutsub":
    "Build influence and create compelling content that’s distinctly yours.",
  "home.Connect": "Connect",
  "home.Connectsub":
    "Share and grow your brand with our diverse, global community.",
  "home.events": "Events",
  "home.eventssub":
    "Participate in events and competitions and get rewarded for engaging with the commmunity.",
  "home.safetyPrivacy": "Safety and Privacy",
  "home.safetyPrivacysub":
    "Your privacy matters, and our community guidelines is the yard stick.",
  "home.Languages": "Languages",
  "home.Languagessub":
    "Our App supports English, Arabic and many more languages.",
  "home.machineLearning": "Machine Learning",
  "home.machineLearningsub":
    "We are inclined to suggest the best content for you that suits your interests.",
  "home.contactUS": "Contact Us",
  "home.contactUSsub": "Keep in Touch",
  "home.contactUSsubheadder1":
    "Our team is always happy to hear from you and get back to you",
  "home.contactUSsubheadder2":
    "Reach out to us whenever you wish, your opinion greatly matters",
  "home.sendmessage": "Send Message",
  "home.name": "Name",
  "home.email": "Email Address",
  "home.yourmessage": "Your Message",
  "home.contactusfooter": "Check what you’re missing out. Download Now!",
  "home.support": "Support",
  "home.privacy": "Privacy Policy",
  "home.Terms": "Terms and Conditions",
  "home.guidelines": "Community Guidelines",
  "home.followus": "Follow us on",
  "home.apple": "Download on the",
  "home.google": "GET IT ON",
  "home.namefeedback": "Name is required!",
  "home.emailfeedBack1": "Email is required!",
  "home.emailfeedBack2": "This is not a valid email format!",
  "home.messagefeedBack1": "Message is required",
  "home.messagefeedBack2": "Message must be more than 10 characters",
  "home.messagefeedBack3": "Message cannot exceed more than 15 characters",
};

export default translationsEn;
