import "../../../src/App.css";

import cookies from "js-cookie";
import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InjectIntl from "react-intl-inject";
import { useLocation } from "react-router-dom";

import logoImg from "../../assets/seezittLogoDark.png";
import {
  ChevIcon,
  HeroBg,
  HeroContainer,
  HeroContent,
  HeroH1,
  HeroP,
  MyImage,
  VideoBg,
} from "../Hero/HeroElements";
import Video from "../videos/video.mp4";
import AppleLogo from "./../../assets/Apple.png";
import GoogleLogo from "./../../assets/Google.png";
import './styles.css';

// const iOSUrl =
//   "https://itunes.apple.com/us/app/all-of-the-lights/id959389722?mt=8";
// const androidUrl = "https://play.google.com";

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    dir: "rtl",
    country_code: "sa",
  },
  {
    code: "fr",
    name: "French",
    country_code: "fr",
  },
];

const Hero = (props) => {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t, i18n } = useTranslation();
  //   const [hover, setHover] = useState(false);
  //   const [header, setHeader] = useState("header");
  const location = useLocation();
  const [visible, setVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 780);

  useEffect(() => {
    console.log("SeezItt");
    document.body.dir = (currentLanguage && currentLanguage.dir) || "ltr";
    document.title = t("Seezitt");
  }, [currentLanguage, t]);

  const toggleVisible = () => {
    if (visible) {

    }
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 0) {
      setVisible(false);
    } else if (scrolled <= 0) {
      setVisible(true);
    }
  };
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "auto",
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    });
  };

  useEffect(() => {
    if (location.hash === "#About") {
      /*   window.history.scrollRestoration = 'manual' */
      const section = document.getElementById("About");
      section.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    } else if (location.hash === "#Feature") {
      const section = document.getElementById("Feature");
      section.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    } else if (location.hash === "#Discover") {
      const section = document.getElementById("Discover");
      section.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    } else if (location.hash === "#ContactUs") {
      const section = document.getElementById("ContactUs");
      section.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    } else if (location.hash === "#Download") {
      const section = document.getElementById("Download");
      section.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    } else {
      window.addEventListener("scroll", toggleVisible);
    }
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 780);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <InjectIntl>
        {({ intl }) => (
          <div className="myvideo">
            <HeroContainer
              id="Download"
              style={{
                fontFamily: i18n.language === "ar" ? "Tajawal" : "Lexend",
              }}
            >
              <HeroBg>
                <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
              </HeroBg>
              <HeroContent>
                <div>
                  <MyImage src={logoImg} className="" />
                </div>
                <HeroH1>{t("home.heroHead")} </HeroH1>
                <HeroP>{t("home.heroSubhead")}</HeroP>
                <div className="flex flex-col items-center justify-center flex-shrink-0 mt-5 space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4 lg:ml-4 lg:mt-0 lg:justify-end">
                  <div className=" flex ">
                    {currentLanguageCode === 'ar' ? (
                      <>
                        <a href="https://play.google.com/store/apps/details?id=com.posh.seezitt">
                          {isMobile ? (
                            <img src={GoogleLogo} alt="" width={"150px"} />
                          ) : (
                            <button className="market-btn google-btn">
                              <div>
                                <div className="text-small">{t("home.google")}</div>
                                <div className="text-2xl">Google Play</div>
                              </div>
                            </button>
                          )}
                        </a>
                        <a href="https://apps.apple.com/qa/app/seezitt/id6444735823">
                          {isMobile ? (
                            <img
                              src={AppleLogo}
                              alt=""
                              width={"150px"}
                              style={{ marginRight: "10px" }}
                            />
                          ) : (
                            <button className="market-btn apple-btn">
                              <div>
                                <div className="text-small">{t("home.apple")}</div>
                                <div className="text-2xl">App Store</div>
                              </div>
                            </button>
                          )}
                        </a>
                      </>
                    ) : (
                      <>
                        <a href="https://apps.apple.com/qa/app/seezitt/id6444735823">
                          {isMobile ? (
                            <img
                              src={AppleLogo}
                              alt=""
                              width={"150px"}
                              style={{ marginRight: "10px" }}
                            />
                          ) : (
                            <button className="market-btn apple-btn">
                              <div>
                                <div className="text-small">{t("home.apple")}</div>
                                <div className="text-2xl">App Store</div>
                              </div>
                            </button>
                          )}
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.posh.seezitt">
                          {isMobile ? (
                            <img src={GoogleLogo} alt="" width={"150px"} />
                          ) : (
                            <button className="market-btn google-btn">
                              <div>
                                <div className="text-small">{t("home.google")}</div>
                                <div className="text-2xl">Google Play</div>
                              </div>
                            </button>
                          )}
                        </a>

                      </>
                    )}

                  </div>
                </div>
                <ChevIcon
                  size={20}
                  color="white"
                  onClick={scrollToBottom}
                ></ChevIcon>
              </HeroContent>
            </HeroContainer>
          </div>
        )}
      </InjectIntl >
    </>
  );
};

export default Hero;
