import 'react-toastify/dist/ReactToastify.css';

import classNames from 'classnames';
import cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InjectIntl from 'react-intl-inject';

import heroImg from '../../assets/heroImg.png';
import logoWhite from '../../assets/seezittLogoDark.png';
import Contactus from '../Contactus/Contactus';
import Navbar from '../Navbar/Navbar';
import Navbarr from '../Navbarr';
import Sidebar from '../SideBar';
import styles from './Faqs.module.scss';
import FaqSection from './faqSection/FaqSection';

// import Sidebarr from '../Sidebarr';
// import Footert from '../footerr/Footer';
const languages = [
	{
		code: 'en',
		name: 'English',
		country_code: 'gb',
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl',
		country_code: 'sa',
	},
	{
		code: "fr",
		name: "French",
		country_code: "fr",
	},
];

const Faqs = ({ className, language, setLanguage }) => {
	const currentLanguageCode = cookies.get('i18next') || 'en';
	const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
	const { t, i18n } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		console.log('Seezitt');
		document.body.dir = (currentLanguage && currentLanguage.dir) || "ltr";
		document.title = t('Seezitt');
	}, [currentLanguage, t]);

	const handleSearchInputChange = (event) => {
		setSearchQuery(event.target.value);
	};

	return (
		<>
			<InjectIntl>
				{({ intl }) => (
					<div>
						<Navbarr toggle={toggle} setLanguage={setLanguage} language={language} faqsNav={true} />
						<div
							className={classNames(styles.root, className)}
							style={{
								fontFamily: i18n.language === 'ar' ? 'Tajawal' : 'Lexend',
							}}>
							{window.location.pathname === '/faqs' && <Sidebar isOpen={isOpen} toggle={toggle} />}
							{window.location.pathname === '/FAQs' && (
								<Navbar toggle={toggle} setLanguage={setLanguage} language={language} faqsNav={true} />
							)}
							<div className={styles.heroSection} id={'FAQs'}>
								<img src={heroImg} alt='' className={styles.heroImg} />
								<img src={logoWhite} alt='' className={styles.logoWhite} />
							</div>
							<div className={styles.searchBar}>
								{currentLanguageCode === 'en' ? (
									<input className={styles.searchInput} placeholder='Search' onChange={handleSearchInputChange} />
								) : currentLanguageCode === 'ar' ? (
									<input className={styles.searchInput} placeholder='ابحث' onChange={handleSearchInputChange} />
								) : (
									''
								)}
							</div>
							<FaqSection
								className={styles.faqSection}
								setLanguage={setLanguage}
								language={language}
								searchQuery={searchQuery}
							/>
						</div>
						<Contactus setLanguage={setLanguage} language={language} />
					</div>
				)}
			</InjectIntl>
		</>
	);
};

export default Faqs;
