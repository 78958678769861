import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Footer from "../../components/Footer/Footer";
import "../Discover/Discover.css";
import { Form, FormButton, FormH1, FormInput, FormTextArea } from "../Discover/DiscoverElements";

import { Col } from "react-bootstrap";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../SideBar";

import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import InjectIntl from "react-intl-inject";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Container } from "../ButtonElements";
import AppleLogo from "./../../assets/Apple.png";
import GoogleLogo from "./../../assets/Google.png";

import emailBlobIcon from '../../assets/emailBlobIcon.svg';
import locationBlobIcon from '../../assets/locationBlobIcon.svg';
import phoneBlobIcon from '../../assets/phoneBlobIcon.svg';

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    dir: "rtl",
    country_code: "sa",
  },
  {
    code: "fr",
    name: "French",
    country_code: "fr",
  },
];

function Contactus(props) {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 780);

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    console.log("SeezItt");
    document.body.dir = (currentLanguage && currentLanguage.dir) || "ltr";
    document.title = t("Seezitt");
    setFormErrors({ email: "", name: "", message: "" });
  }, [currentLanguage, t]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 780);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate(formValues);

    if (Object.keys(errors).length === 0) {
      // If there are no errors, clear the form values
      setFormValues({ email: "", name: "", message: "" });
    }

    setFormErrors(errors);
    setIsSubmit(true);
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const specialCharacterRegex = /<|>/; // Regular expression for special characters

    if (!values.name) {
      errors.name = t("home.namefeedback");
    } else if (specialCharacterRegex.test(values.name)) {
      errors.name = "Name cannot contain special characters.";
    }

    if (!values.email) {
      errors.email = t("home.emailfeedBack1");
    } else if (!regex.test(values.email)) {
      errors.email = t("home.emailfeedBack2");
    }

    if (!values.message) {
      errors.message = t("home.messagefeedBack1");
    } else if (values.message.length < 10) {
      errors.message = t("home.messagefeedBack2");
    } else if (specialCharacterRegex.test(values.message)) {
      errors.message = "Message cannot contain special characters.";
    }

    return errors;
  };

  const showToastMessage = () => {
    toast.success(t("home.successMsg"), {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  return (
    <>
      <InjectIntl>
        {({ intl }) => (
          /*          <div style={{ direction: (props.language === 'ar' || props.language === 'ur') ? 'rtl' : 'ltr' }}> */
          <div style={{ fontFamily: i18n.language === "ar" ? "Tajawal" : "Lexend" }}>
            {window.location.pathname === "/contactus" && <Sidebar isOpen={isOpen} toggle={toggle} /> && (
              <Navbar toggle={toggle} setLanguage={props.setLanguage} language={props.language} />
            )}
            <div id="ContactUs">
              <div className="contact-container" style={{ marginTop: '40px' }}>
                <Container className="">
                  <div className="containerForm contact-section">
                    <div className="row">
                      <div className="text-center contactTitle " style={{ marginTop: '0px' }}>{t("home.contactUS")}</div>
                      <div className="indicatorWrapper" style={{ marginBottom: '0px' }}>
                        <img src="/img/Active Indicator.png" className="indicator" alt="indicator" />
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <Form className="text-center" id="create-course-form" style={{ padding: '0px 0px' }} onSubmit={handleSubmit}>
                    <Row>
                      <Col md={6} className={'formCol'}  >
                        <div className="text-center mb-4 subhead">{t("home.contactUS")}</div>
                        <FormH1>{t("home.contactUSsub")}</FormH1>
                        <Row className="text-center mb-4">
                          <Col className="col-md-12 text-center">
                            <div className="text-center contactdes">
                              {t("home.contactUSsubheadder1")}
                              {t("home.contactUSsubheadder2")}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <FormInput type="text" className="" name="name" value={formValues.name} onChange={handleChange} placeholder={t("home.name")} />
                          <p className="feedback">{formErrors.name}</p>
                          <FormInput type="text" name="email" value={formValues.email} onChange={handleChange} placeholder={t("home.email")} />
                          <p className="feedback">{formErrors.email}</p>
                          <FormTextArea
                            type="text"
                            name="message"
                            value={formValues.message}
                            onChange={handleChange}
                            placeholder={t("home.yourmessage")}
                          ></FormTextArea>
                          <p className="feedback">{formErrors.message}</p>
                          <FormButton onClick={showToastMessage}>{t("home.sendmessage")}</FormButton>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <div className={`${currentLanguageCode === 'ar' ? 'rightSideFormAr' : 'rightSideForm'}`}>
                          <div className={`${currentLanguageCode === 'ar' ? 'contactInfoDivAr' : 'contactInfoDiv'}`}>
                            <div className={`${currentLanguageCode === 'ar' ? 'infoItemAr' : 'infoItem'}`}>
                              <img src={emailBlobIcon} alt='' />
                              <p className="infoText">info@poshenterpriseinc.com</p>
                            </div>
                            <div className={`${currentLanguageCode === 'ar' ? 'infoItemAr' : 'infoItem'}`}>
                              <img src={phoneBlobIcon} alt='' />
                              <p className="infoText">+1 (408)-807-6383</p>
                            </div>
                            <div className={`${currentLanguageCode === 'ar' ? 'infoItemAr' : 'infoItem'}`} style={{ alignItems: 'flex-start' }}>
                              <img src={locationBlobIcon} alt='' />
                              <div className={`${currentLanguageCode === 'ar' ? 'locationItemAr' : 'locationItem'}`}>
                                <p className="infoText">305 Vineyard Town Center #325</p>
                                <p className="infoText">Morgan Hill, CA 95037</p>
                                <p className="infoText">USA</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  {Object.keys(formErrors).length === 0 && isSubmit ? <ToastContainer /> : null}
                  {/* Download app section*/}
                  <h1 className="text-center mb-4  contactusfooter">{t("home.contactusfooter")}</h1>
                  <div className="text-center">
                    <div className="" id="DownloadApp" style={{ marginBottom: "100px" }}>
                      <div className="flex flex-col items-center justify-center playstore flex-shrink-0 mt-5 space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4 lg:ml-4 lg:mt-0 lg:justify-center">
                        <div className="flex" style={{ marginTop: "20px" }}>
                          <a href="https://apps.apple.com/qa/app/seezitt/id6444735823">
                            {isMobile ? (
                              <img src={AppleLogo} alt="" width={"150px"} style={{ marginRight: "10px" }} />
                            ) : (
                              <button className="market-btn apple-btn">
                                <div>
                                  <div className="text-small">{t("home.apple")}</div>
                                  <div className="text-2xl">App Store</div>
                                </div>
                              </button>
                            )}
                          </a>
                          <a href="https://play.google.com/store/apps/details?id=com.posh.seezitt">
                            {isMobile ? (
                              <img src={GoogleLogo} alt="" width={"150px"} />
                            ) : (
                              <button className="market-btn google-btn">
                                <div>
                                  <div className="text-small">{t("home.google")}</div>
                                  <div className="text-2xl">Google Play</div>
                                </div>
                              </button>
                            )}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Container>
              </div>
              {/* {window.location.pathname === "/" && <Footer setLanguage={props.setLanguage} language={props.language} />}
              {window.location.pathname === "/contactus" && <Footerr setLanguage={props.setLanguage} language={props.language} />} */}
              <Footer setLanguage={props.setLanguage} language={props.language} />
            </div>
          </div>
        )}
      </InjectIntl>
    </>
  );
}

export default Contactus;
