import classNames from 'classnames';
import cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './FaqSection.module.scss';
import './styles.css';

import noResultGraphic from '../../../assets/noResultsGraphic.svg';

import nextPageBtn from '../../../assets/nextPageBtn.svg';
import prevPageBtn from '../../../assets/prevPageBtn.svg';

const faqsData = require('./questions.json');
const faqsDataAr = require('./questionsAr.json');

const languages = [
	{
		code: 'en',
		name: 'English',
		country_code: 'gb',
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl',
		country_code: 'sa',
	},
	{
		code: "fr",
		name: "French",
		country_code: "fr",
	},
];

function FaqSection({ className, searchQuery, language, setLanguage }) {
	const currentLanguageCode = cookies.get('i18next') || 'en';
	const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
	const { t, i18n } = useTranslation();
	// const [faqs, setFaqs] = useState(faqsData.questions);
	const selectedQuestions = currentLanguage.code === 'ar' ? faqsDataAr.questions : faqsData.questions;
	// const faqs = faqsData.questions;
	const [currentPage, setCurrentPage] = useState(1);
	const [faqsPerPage] = useState(6);
	const [activePageNumber, setActivePageNumber] = useState(1);

	useEffect(() => {
		console.log('SeezItt');
		document.body.dir = (currentLanguage && currentLanguage.dir) || "ltr";
		document.title = t('Seezitt');
	}, [currentLanguage, t]);

	const handleClick = (event) => {
		const pageNumber = Number(event.currentTarget.id);
		setCurrentPage(pageNumber);
		setActivePageNumber(pageNumber);
	};


	useEffect(() => {
		if (searchQuery === '') {
			setActivePageNumber(1);
		}
	}, [searchQuery]);

	// Logic for displaying current faqs
	const indexOfLastFaq = currentPage * faqsPerPage;
	const indexOfFirstFaq = indexOfLastFaq - faqsPerPage;
	const filteredFaqs = selectedQuestions.filter((faq) =>
		faq.question.toLowerCase().includes(searchQuery?.toLowerCase() || '')
	);
	const currentFaqs = filteredFaqs.slice(indexOfFirstFaq, indexOfLastFaq);

	// Create a function to highlight the search query
	const highlightSearchQuery = (text) => {
		if (!searchQuery) {
			return text;
		}

		// Use regular expressions to perform a case-insensitive search and replace
		const regex = new RegExp(`(${searchQuery})`, 'gi');
		return text.replace(regex, '<span class="highlighted">$1</span>');
	};

	const renderFaqs = currentFaqs.length > 0
		? currentFaqs.map((faq, index) => {
			const questionNumber = indexOfFirstFaq + index + 1; // Calculate the question number
			const highlightedQuestion = highlightSearchQuery(faq.question);

			return (
				<details key={index}>
					<summary>
						{questionNumber}. <span dangerouslySetInnerHTML={{ __html: highlightedQuestion }} />
					</summary>
					<p className={styles.answer}>{faq.answer}</p>
				</details>
			);
		}) : ('')

	// Logic for displaying page numbers
	const totalPages = Math.ceil(filteredFaqs.length / faqsPerPage);
	const maxVisiblePages = 4; // Number of page numbers to show before the ellipsis

	// Calculate the start and end page numbers
	let startPageNumber;
	let endPageNumber;

	if (totalPages <= maxVisiblePages) {
		startPageNumber = 1;
		endPageNumber = totalPages;
	} else if (activePageNumber <= maxVisiblePages - 1) {
		startPageNumber = 1;
		endPageNumber = maxVisiblePages;
	} else if (activePageNumber > totalPages - maxVisiblePages + 1) {
		startPageNumber = totalPages - maxVisiblePages + 1;
		endPageNumber = totalPages;
	} else {
		startPageNumber = activePageNumber - Math.floor(maxVisiblePages / 2);
		endPageNumber = startPageNumber + maxVisiblePages - 1;
	}

	const pageNumbersToRender = [];


	for (let i = startPageNumber; i <= endPageNumber; i++) {
		pageNumbersToRender.push(i);
	}

	const renderPageNumbers = pageNumbersToRender.map((number) => {
		const isActive = number === activePageNumber;
		return (
			<li
				key={number}
				id={number.toString()}
				onClick={handleClick}
				className={isActive ? styles.paginationListItemActive : styles.paginationListItem}
				style={{ cursor: 'pointer' }}
			>
				{number}
			</li>
		);
	});

	const prevPage = activePageNumber - 1;
	const nextPage = activePageNumber + 1;


	return (
		<div className={classNames('container', styles.container, className)} style={{ fontFamily: i18n.language === 'ar' ? 'Tajawal' : 'Lexend' }}>
			{(currentFaqs.length === 0) ? (
				<div className={styles.noResultContainer}>
					<div className={styles.noResultGraphicDiv}>
						<img src={noResultGraphic} alt='' />
						<p className={styles.noResultText}>No results found</p>
					</div>
					<div>
						<p className={styles.noResultSubText}>
							Try using common keywords like "account" or "privacy" to find
							what you're looking for
						</p>
					</div>
				</div>
			) : (
				<>
					<div className={styles.title}>
						{currentLanguageCode === 'en'
							? 'Frequently asked questions'
							: currentLanguageCode === 'ar'
								? 'الأسئلة المتكررة'
								: ''}
					</div>
					{renderFaqs}
					<ul id='page-numbers' className={currentLanguageCode === 'ar' ? styles.paginationListAr : styles.paginationList}>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<div style={{
								display: 'flex', justifyContent: 'center', alignItems: 'center',
								marginRight: currentLanguageCode === 'ar' ? '20px' : '20px'
							}}>
								<li
									key="prev"
									id={prevPage.toString()}
									onClick={handleClick}
								// className={styles.paginationListItem}
								>
									<img src={currentLanguageCode === 'ar' ? nextPageBtn : prevPageBtn} alt='' style={{
										cursor: 'pointer', display: prevPage >= 1 ? 'block' : 'none', position: 'relative',
										left: currentLanguageCode === 'ar' ? '16px' : '0px'
									}} onClick={() => activePageNumber - 1} />
								</li>
							</div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{activePageNumber >= 4 && (
									<span
										className={styles.paginationListItem}
										onClick={() => setActivePageNumber(1)} // Go to the first page
										style={{ cursor: 'pointer' }}
									>
										1
									</span>
								)}
								{activePageNumber >= 4 && <span className={styles.paginationListItem}>...</span>}
								{renderPageNumbers}
								{activePageNumber < totalPages - 2 && <span className={styles.paginationListItem}>...</span>}
								{activePageNumber < totalPages - 2 && (
									<span
										className={styles.paginationListItem}
										onClick={() => setActivePageNumber(totalPages)} // Go to the last page
										style={{ cursor: 'pointer' }}
									>
										{totalPages}
									</span>
								)}
							</div>
							<div style={{
								display: 'flex', justifyContent: 'center', alignItems: 'center',
								marginRight: currentLanguageCode === 'ar' ? '25px' : '25px'
							}}>
								{nextPage <= totalPages && (
									<li
										key="next"
										id={nextPage.toString()}
										onClick={handleClick}
									// className={styles.paginationListItem}
									>
										<img src={currentLanguageCode === 'ar' ? prevPageBtn : nextPageBtn} alt='' style={{ marginLeft: currentLanguageCode === 'ar' ? '0' : '20px', cursor: 'pointer' }} onClick={() => activePageNumber + 1} />
									</li>
								)}
							</div>
							{/* <Pagination /> */}
						</div>
					</ul>
				</>
			)}
		</div>
	);
}

export default FaqSection;
