import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';

export const Nav = styled.nav`
	background: ${({ scrollNav, faqsNav }) => (scrollNav || faqsNav ? '#48455E' : 'transparent')};
	height: 80px;
	margin-top: ${({ faqsNav }) => (faqsNav ? '0px' : '-80px')};
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 17px;
	/*   font-family:lexend; */
	position: sticky;
	top: 0;
	z-index: 10;

	@media screen and (max-width: 960px) {
		transition: 0.8s all ease;
		// background: transparent;
	}
`;

export const NavbarContainer = styled.div`
	display: flex;
	justify-content: space-around;
	height: 80px;
	z-index: 1;
	width: 100%;

	max-width: 1224px;
`;
export const NavLogo = styled(LinkR)`
	color: #fff;
	justify-self: center;
	cursor: pointer;
	font-size: 1.5rem;
	display: flex;
	align-items: center;

	font-weight: bold;
	text-decoration: none;
	@media screen and (max-width: 768px) {
		align-items: center;
	}
	@media screen and (max-width: 480px) {
		align-items: center;
	}
`;
export const Logo = styled.img`
	color: #fff;
	justify-self: center;
	cursor: pointer;
	font-size: 1.5rem;
	display: flex;
	align-items: center;

	font-weight: bold;
	text-decoration: none;

	@media screen and (max-width: 768px) {
		color: #fff;
		justify-self: flex-start;
		cursor: pointer;
		height: 25px;
		width: 73px;

		display: flex;
		align-items: center;
		margin-left: 24px;
		font-weight: bold;
		text-decoration: none;
	}
	@media screen and (max-width: 480px) {
		color: #fff;
		justify-self: flex-start;
		cursor: pointer;
		height: 25px;
		width: 73px;
		display: flex;
		align-items: center;

		font-weight: bold;
		text-decoration: none;
	}
`;
export const MobileIcon = styled.div`
	display: none;

	@media screen and (max-width: 768px) {
		display: block;
		position: absolute;
		top: 20px;
		left: 34px;
		transform: translate(-100%, 60%);
		font-size: 20px;
		cursor: pointer;
		color: #fff;
	}
	@media screen and (max-width: 480px) {
		display: block;
		position: absolute;
		top: 20px;
		left: 34px;
		transform: translate(-100%, 60%);
		font-size: 20px;
		cursor: pointer;
		color: #fff;
	}
`;
export const NavMenu = styled.ul`
	display: flex;
	justify-content: center;
	list-style: none;
	text-align: center;
	margin-right: -50px;

	@media screen and (max-width: 768px) {
		display: none;
		background: transparent;
	}
`;
export const NavItem = styled.li`
	height: 80px;
`;
export const NavLinks = styled(LinkS)`
	color: #fff;
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0 1rem;
	height: 100%;
	cursor: pointer;

	font-size: 17px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0em;
	text-align: center;

	&.active {
		border-bottom: 3px solid #9f95dc;
	}
	&:hover {
		color: #9f95dc;
	}
`;
export const NavLink = styled(LinkS)`
	color: #fff;
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0 1rem;
	height: 100%;
	cursor: pointer;

	font-size: 17px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0em;
	text-align: center;

	&.active {
		border-bottom: 3px solid #9f95dc;
	}
	&:hover {
		color: #9f95dc;
	}
`;
export const NavBtn = styled.nav`
	display: flex;
	align-items: center;
	margin-left: 10px;
	@media screen and (max-width: 768px) {
		display: none;
	}
`;

export const Links = styled(LinkS)`
	align-items: center;
	text-decoration: none;

	cursor: pointer;
`;
export const Link = styled(LinkR)`
	align-items: center;
	text-decoration: none;

	cursor: pointer;
`;

export const NavBtnLink = styled(LinkR)`
	border-radius: 50px;
	background: #5d41de;
	white-space: nowrap;
	padding: 13px 28px;
	color: #fff;
	font-size: 17px;
	outline: none;
	border: none;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	text-decoration: none;
	margin-left: 10px;
	margin-right: 10px;

	&:hover {
		color: #fff !important;
	}
`;
