import classNames from "classnames";
import i18next from "i18next";
import cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import './styles.css';

import {
  SidebarContainer,
  SidebarLink,
  SidebarLinks,
  SidebarMenu,
  SidebarRoute,
  SidebarWrapper,
  SideBtnWrap
} from "./SidebarElements";

// import DropdownButton from 'react-bootstrap/DropdownButton';
// import Dropdown from 'react-bootstrap/Dropdown';
// import InjectIntl from 'react-intl-inject';
// import { FormattedMessage } from 'react-intl';
// import styles from './styles.css';

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    dir: "rtl",
    country_code: "sa",
  },
  {
    code: "fr",
    name: "French",
    country_code: "fr",
  },
];

const Sidebar = ({ isOpen, toggle }) => {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  //   const [scrollNav, setScrollNav] = useState(false);
  //   const changeNav = () => {
  //     if (window.scrollY >= 80) {
  //       setScrollNav(true);
  //     } else {
  //       setScrollNav(false);
  //     }
  //   };
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "auto",
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    });
  };

  useEffect(() => {
    console.log("SeezItt");
    document.body.dir = (currentLanguage && currentLanguage.dir) || "ltr";
    document.title = t("Seezitt");
  }, [currentLanguage, t]);

  const [isRotated, setRotated] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleRotation = () => {
    setRotated(!isRotated);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}
      style={{ padding: currentLanguageCode === 'ar' ? '0px 57px' : '' }}>
      <div className={currentLanguageCode === 'ar' ? "closeIconDivAr" : "closeIconDiv"}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
          <path d="M2 17L10 9.5M18 2L9.99847 9.5M9.99847 9.5L2 2M10 9.5L18 17" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M2 17L10 9.5M18 2L9.99847 9.5M9.99847 9.5L2 2M10 9.5L18 17" stroke="white" stroke-opacity="0.2" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      <SidebarWrapper>
        <SidebarMenu
          style={{ marginRight: currentLanguageCode === 'ar' ? '-10px' : '' }}>
          <SidebarLinks
            to={{
              pathname: "/",
              hash: "About",
            }}
            onClick={toggle}
          >
            {t("home.about")}
          </SidebarLinks>
          <SidebarLinks
            to={{
              pathname: "/",
              hash: "Feature",
            }}
            onClick={toggle}
          >
            {t("home.features")}
          </SidebarLinks>
          <SidebarLinks
            to={{
              pathname: "/",
              hash: "Discover",
            }}
            onClick={toggle}
          >
            {t("home.discover")}
          </SidebarLinks>
          <SidebarLinks to="/faqs" onClick={toggle}>
            {/* {currentLanguageCode === "en" ? "FAQs" : " الأسئلة المتكررة"} */}
            {t("home.faqs")}
          </SidebarLinks>
          <SidebarLinks
            to={{
              pathname: "/",
              hash: "ContactUs",
            }}
            onClick={toggle}
          >
            {t("home.contactUs")}
          </SidebarLinks>
          <SidebarLink>
            <div className="language-select" onClick={toggleDropdown}>
              <div className="language-select-root">
                <div className="dropdown">
                  <button
                    className="btn p-0 body-font font-lexend"
                    type="button"
                    id="dropdownDefaultButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={toggleRotation}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      fontWeight: "600",
                      fontSize: "18px",
                      color: 'white',
                      marginBottom: '32px',
                      lineHeight: "18px",
                      width: '330px',
                      border: 'none',
                      gap: '240px',
                    }}
                  >
                    {currentLanguage.name}
                    <span className={isRotated ? 'rotate' : 'notRotated'}>
                      <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.825865 0.212584C1.08354 -0.0450935 1.48677 -0.0685187 1.77091 0.142308L1.85231 0.212584L8.11328 6.47323L14.3743 0.212584C14.6319 -0.0450929 15.0352 -0.0685181 15.3193 0.142309L15.4007 0.212584C15.6584 0.470262 15.6818 0.873485 15.471 1.15762L15.4007 1.23903L8.6265 8.01322C8.36883 8.2709 7.9656 8.29433 7.68146 8.0835L7.60006 8.01322L0.825865 1.23903C0.54242 0.955584 0.54242 0.496029 0.825865 0.212584Z" fill="white" />
                      </svg>
                    </span>
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                    onClick={toggle}
                  >
                    {languages.map(({ code, name, country_code }) => (
                      <li key={country_code}>
                        <div
                          role="button"
                          tabIndex="0" // This makes the div focusable for accessibility
                          className={classNames("dropdown-item", {
                            disabled: currentLanguageCode === code,
                          })}
                          onClick={() => {
                            i18next.changeLanguage(code);
                            toggleRotation()
                          }}
                        >
                          <span
                            className={`flag-icon flag-icon-${country_code} `}
                            style={{
                              opacity: currentLanguageCode === code ? 0.5 : 1,
                            }}
                          ></span>
                          {name}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </SidebarLink>
          <SidebarLink onClick={toggle}>
            <SideBtnWrap>
              <SidebarRoute to="/" onClick={scrollToBottom}
                style={{
                  marginTop: isDropdownOpen ? '95px' : '0px', // Add marginTop property
                }}>
                {t("home.downloadApp")}
              </SidebarRoute>
            </SideBtnWrap>
          </SidebarLink>
        </SidebarMenu>
        <SidebarMenu></SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
