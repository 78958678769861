import { FaAngleDoubleDown } from "react-icons/fa";
import { MdArrowForward, MdKeyboardArrowRight } from "react-icons/md";
import styled, { keyframes } from "styled-components";

export const HeroContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 100vh;
  position: relative;

  :before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(9, 8, 46, 0.5);
    z-index: 2;
  }

  @media screen and (max-width: 768px) {
    height: 60vh;
  }
  @media screen and (max-width: 480px) {
    height: 60vh;
  }
`;
export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`;
export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    margin-top: 1px;
  }

  @media screen and (max-width: 480px) {
    margin-top: 1px;
  }
`;
export const HeroH1 = styled.h1`
  color: #fff;
  font-size: 48px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 20px;
    margin-top:10px;
    padding-left: 14px;
    padding-right: 14px;
}
  }
  @media screen and (max-width: 480px) {
    font-size: 20px;
    margin-top:10px;
    padding-left: 14px;
    padding-right: 14px;
}
  }
`;

export const HeroP = styled.p`
  margin-top: 1px;
  color: #fff !important;
  font-size: 24px;
  text-align: center;


  @media screen and (max-width: 768px) {
    font-size: 12px;
    padding-left: 17px;
    padding-right: 17px;
}
  }
  @media screen and (max-width: 480px) {
    font-size: 12px;
    padding-left: 17px;
    padding-right: 17px;
}
  }
`;
export const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const Wrapper = styled.div`
  @media only screen and (max-width: 399px) {
    width: 20%;
  }
`;

export const BtnAppstore = styled.button`
  width: 220.1px;
  height: 64.74px;
  border-radius: 10px;
  background: #000;
  color: white;
  border: 2px solid #fff;
  text-align: center;
  margin: 5px;
  display: inline-block;

  &:hover {
    background: #3b5998;
    opacity: 0.6;
  }
`;
export const BtnPlaystore = styled.button`
  margin: 5px;
  width: 220.1px;
  height: 64.74px;
  border-radius: 10px;
  background: #000;
  color: white;
  border: 2px solid #fff;
  text-align: center;

  &:hover {
    background: #3b5998;
    opacity: 0.6;
  }
`;
export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;
export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;
export const MyImage = styled.img`
  color: #fff;
  flex-direction: row;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 100px;
  @media screen and (max-width: 768px) {
    height: 79.5px;
    width: 78.5px;
    margin-top: 100px;
  }
  @media screen and (max-width: 480px) {
    height: 79.5px;
    width: 78.5px;
    margin-top: 100px;
  }
`;
const MoveUpDown = keyframes`
0% { transform: translateY(0px); }
100% { transform: translateY(-20px); }
`;

export const ChevIcon = styled(FaAngleDoubleDown)`
  margin-top: 190px;

  text-align: center;
  animation-name: ${MoveUpDown};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  width: 100%;
  cursor: pointer;
  position: relative;
  @media screen and (max-width: 768px) {
    margin-bottom: 0px;
    margin-top: 35px;
  }
  @media screen and (max-width: 480px) {
    margin-bottom: 0px;
    margin-top: 35px;
  }
`;

export const LineImage = styled.img`
  z-index: 2;
  text-align: center;
  height: 5px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: white;

  position: absolute;
  margin-top: -14px;
  @media screen and (max-width: 768px) {
    text-align: center;
    height: 3px;
    margin-top: 50px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 480px) {
    text-align: center;
    height: 3px;
    margin-top: 37px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
`;
