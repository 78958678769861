import classNames from "classnames";
import i18next from "i18next";
import cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import InjectIntl from "react-intl-inject";
import { animateScroll as scroll } from "react-scroll";

import {
  Logo,
  MobileIcon,
  Nav,
  NavbarContainer,
  NavBtn,
  NavBtnLink,
  NavItem,
  NavLink,
  NavLogo,
  NavMenu,
} from "./Elements";

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    dir: "rtl",
    country_code: "sa",
  },
  {
    code: "fr",
    name: "French",
    country_code: "fr",
  },
];

const Navbar = (toggle) => {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t, i18n } = useTranslation();
  const [scrollNav, setScrollNav] = useState(false);

  useEffect(() => {
    console.log("SeezItt");
    document.body.dir = (currentLanguage && currentLanguage.dir) || "ltr";
    document.title = t("Seezitt");
  }, [currentLanguage, t, currentLanguageCode]);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  return (
    <InjectIntl>
      {({ intl }) => (
        <>
          <IconContext.Provider value={{ color: "#fff" }}>
            <Nav scrollNav={scrollNav}>
              <NavbarContainer
                style={{
                  fontFamily: i18n.language === "ar" ? "Tajawal" : "Lexend",
                }}
              >
                <NavLogo to="/" onClick={toggleHome}>
                  <Logo src="/img/logo.png" />
                </NavLogo>
                <MobileIcon onClick={toggle}>
                  <FaBars />
                </MobileIcon>
                <NavMenu>
                  <NavItem>
                    <NavLink
                      to={{
                        pathname: "/",
                        hash: "About",
                      }}
                      onClick={toggle}
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                      }}
                    >
                      {/* {t('home.about')}</NavLink> */}
                      fsdfsdfsdf
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to={{
                        pathname: "/",
                        hash: "Feature",
                      }}
                      onClick={toggle}
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                      }}
                    >
                      {t("home.features")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to={{
                        pathname: "/",
                        hash: "Discover",
                      }}
                      onClick={toggle}
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                      }}
                    >
                      {t("home.discover")}
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      to={{
                        pathname: "/faqs",
                        hash: "FAQs",
                      }}
                      onClick={toggle}
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                      }}
                    >
                      {t("home.faqs")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to={{
                        pathname: "/",
                        hash: "ContactUs",
                      }}
                      onClick={toggle}
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                      }}
                    >
                      {t("home.contactUs")}
                    </NavLink>
                  </NavItem>
                </NavMenu>

                <NavBtn>
                  <NavBtn>
                    <div className="language-select">
                      <div className="d-flex justify-content-end align-items-center language-select-root">
                        <div className="dropdown">
                          <button
                            className="btn btn-link dropdown-toggle mb-7"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {languages}
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                            style={{
                              fontFamily:
                                i18n.language === "ar" ? "Tajawal" : "Lexend",
                            }}
                          >
                            {languages.map(({ code, name, country_code }) => (
                              <li key={country_code}>
                                <div
                                  role="button"
                                  tabIndex="0" // This makes the div focusable for accessibility
                                  className={classNames("dropdown-item", {
                                    disabled: currentLanguageCode === code,
                                  })}
                                  onClick={() => {
                                    i18next.changeLanguage(code);
                                  }}
                                >
                                  {/* Your link content goes here */}

                                  <span
                                    className={`flag-icon flag-icon-${country_code} `}
                                    style={{
                                      opacity:
                                        currentLanguageCode === code ? 0.5 : 1,
                                    }}
                                  ></span>
                                  {name}
                                </div>
                                {/* </a> */}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </NavBtn>
                  <NavBtnLink
                    to={{
                      pathname: "/",
                      hash: "DownloadApp",
                    }}
                    onClick={toggle}
                  >
                    {t("home.downloadApp")}
                  </NavBtnLink>
                </NavBtn>
              </NavbarContainer>
            </Nav>
          </IconContext.Provider>
        </>
      )}
    </InjectIntl>
  );
};

export default Navbar;
