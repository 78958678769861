import React, { useState } from 'react';
import About from '../components/About/About';
import Navbarr from '../components/Navbarr';
import Sidebar from '../components/SideBar';
import Hero from '../components/Hero/index';
import Feature from '../components/Feature/Feature';
import Discover from '../components/Discover/Discover';
import Contactus from '../components/Contactus/Contactus';

const Home = (props) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div className={isOpen ? 'overlay2' : 'content'}>
			<Sidebar isOpen={isOpen} toggle={toggle} />

			<Navbarr toggle={toggle} setLanguage={props.setLanguage} language={props.language} />
			<Hero setLanguage={props.setLanguage} language={props.language} />
			<About setLanguage={props.setLanguage} language={props.language} />
			<Feature setLanguage={props.setLanguage} language={props.language} />
			<Discover setLanguage={props.setLanguage} language={props.language} />
			{/* <Faqs setLanguage={props.setLanguage} language={props.language} /> */}
			<Contactus setLanguage={props.setLanguage} language={props.language} />
			{/* <Footer setLanguage={props.setLanguage} language={props.language} /> */}
		</div>
	);
};

export default Home;
