import cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import 'swiper/components/effect-coverflow/effect-coverflow.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.scss';
import SwiperCore, { EffectCoverflow, Navigation, Pagination } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import '../../App.css';
import coinImgIcon from '../../assets/coinImgIcon.png';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../SideBar';
import Footerr from '../footerr/Footer';
import './Discover.css';
import './Discover.scss';

SwiperCore.use([EffectCoverflow, Pagination, Navigation]);

const languages = [
	{
		code: 'en',
		name: 'English',
		country_code: 'gb',
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl',
		country_code: 'sa',
	},
	{
		code: "fr",
		name: "French",
		country_code: "fr",
	},
];
function Discover(props) {
	const currentLanguageCode = cookies.get('i18next') || 'en';
	const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
	const { t, i18n } = useTranslation();

	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => {
		setIsOpen(!isOpen);
	};
	useEffect(() => {
		console.log('SeezItt');
		document.body.dir = (currentLanguage && currentLanguage.dir) || "ltr";
		document.title = t('Seezitt');
	}, [currentLanguage, t]);
	return (
		<>
			<div style={{ fontFamily: i18n.language === 'ar' ? 'Tajawal' : 'Lexend' }}>
				{window.location.pathname === '/discover' && <Sidebar isOpen={isOpen} toggle={toggle} /> && (
					<Navbar toggle={toggle} />
				)}
				<div style={{ direction: props.language === 'ar' || props.language === 'ur' ? 'rtl' : 'ltr' }}>
					<div id='Discover' style={{ padding: '1px' }}>
						<div className='container mb-4 discover-section '>
							<div className='row'>
								<div className='text-center featuresTitle '>{t('home.discovertitle')}</div>
								<div className='indicatorWrapper'>
									<img src='/img/Active Indicator.png' className='indicator' alt='indicator' />
								</div>
							</div>
						</div>
						<Container className=''>
							<Swiper
								navigation={true}
								effect={'coverflow'}
								spaceBeetween={80}
								centeredSlides={true}
								slidesPerView={window.innerWidth < 768 ? 1 : 'auto'}
								loop={true}
								coverflowEffect={{
									rotate: 50,
									stretch: 0,
									depth: 100,
									modifier: 1,
									slideShadows: true,
								}}
								pagination={{
									clickable: true,
								}}
								className='mySwiper'>
								<SwiperSlide>
									<Row className='justify-content-center  align-items-center '>
										<Col md={6} xs={6} className='text-center mx-auto '>
											<div className='card text-center' style={{
												display: 'flex',
												flexDirection: 'column', justifyContent: 'space-between',
												gap: '20px'
											}}>
												<div className='card-header'>
													<img
														src='/img/Group 5200.png'
														className='img-fluid mblimg'
														alt='grp'
														style={{ marginTop: '-38px', display: 'inline' }}></img>
												</div>
												<div className='card-body' style={{ padding: '0rem' }}>
													<h5 className='card-title' style={{ color: '#A29BDC' }}>
														{t('home.ourGiftsKit')}
													</h5>
													<p className='card-text p-3'>{t('home.ourGiftsKitsub')}</p>
												</div>
											</div>
										</Col>
										<Col md={6} xs={6} className='text-center mx-auto'>
											<div className='card text-center' style={{
												display: 'flex',
												flexDirection: 'column', justifyContent: 'space-between',
												gap: '20px'
											}}>
												<div className='card-header'>
													<img
														src={coinImgIcon}
														className='img-fluid mblimg'
														alt='grp'
														style={{
															marginTop: '-28px', padding: '0px', display: 'inline',
															height: '142px'
														}}></img>
												</div>
												<div className='card-body' style={{ marginTop: '-20px' }}>
													<div class='card-block'>
														<h5 className='card-title' style={{ color: '#F7CA4B' }}>
															{t('home.caesiumCoins')}
														</h5>
														<p className='card-text p-3'>{t('home.caesiumCoinssub')}</p>
													</div>
												</div>
											</div>
										</Col>
									</Row>
								</SwiperSlide>
								<SwiperSlide>
									<Row className='justify-content-center align-items-center '>
										<Col md={6} xs={6} className='text-center mx-auto'>
											<div className='card text-center' style={{
												display: 'flex',
												flexDirection: 'column', justifyContent: 'space-between',
												gap: '0px'
											}}>
												<div className='card-header'>
													<img
														src='/img/Stand out1.png'
														className='img-fluid mblimg'
														alt='grp'
														style={{
															marginTop: '-38px', display: 'inline',
															width: 'auto'
														}}></img>
												</div>
												<div className='card-body'>
													<h5 className='card-title' style={{ color: '#5448B2' }}>
														{t('home.standOut')}
													</h5>
													<p className='card-text p-3'>{t('home.standOutsub')}</p>
												</div>
											</div>
										</Col>

										<Col md={6} xs={6} className='text-center mx-auto'>
											<div className='card text-center' style={{
												display: 'flex',
												flexDirection: 'column', justifyContent: 'space-between',
												gap: '0px'
											}}>
												<div className='card-header'>
													<img
														src='/img/Grow.png'
														className='img-fluid mblimg '
														alt='grp'
														style={{
															marginTop: '-38px', display: 'inline',
															height: '100%'
														}}></img>
												</div>
												<div className='card-body' style={{ marginTop: '1px' }}>
													<div class='card-block'>
														<h5 className='card-title' style={{ color: '#4280D8' }}>
															{t('home.Connect')}
														</h5>
														<p className='card-text p-3'>{t('home.Connectsub')}</p>
													</div>
												</div>
											</div>
										</Col>
									</Row>
								</SwiperSlide>

								<SwiperSlide>
									<Row className='justify-content-center  align-items-center'>
										<Col md={6} xs={6} className='text-center mx-auto'>
											<div className='card text-center' style={{
												display: 'flex',
												flexDirection: 'column', justifyContent: 'space-between',
												gap: '40px'
											}}>
												<div className='card-header'>
													<img
														src='/img/Group 51871.png'
														className='img-fluid mblimg'
														alt='grp'
														style={{
															marginTop: '-38px', display: 'inline',
															height: '100%'
														}}></img>
												</div>
												<div className='card-body' style={{ marginTop: '-43px' }}>
													<h5 className='card-title' style={{ color: '#958DD6' }}>
														{t('home.events')}
													</h5>
													<p className='card-text p-3'>{t('home.eventssub')}</p>
												</div>
											</div>
										</Col>
										<Col md={6} xs={6} className='text-center mx-auto'>
											<div className='card text-center' style={{
												display: 'flex',
												flexDirection: 'column', justifyContent: 'space-between',
												gap: '40px'
											}}>
												<div className='card-header'>
													<img
														src='/img/Group 51881.png'
														className='img-fluid mblimg'
														alt='grp'
														style={{
															marginTop: '-38px', display: 'inline',
															height: '100%'
														}}></img>
												</div>
												<div className='card-body' style={{ marginTop: '-43px' }}>
													<div class='card-block'>
														<h5 className='card-title' style={{ color: '#4280D8' }}>
															{t('home.safetyPrivacy')}
														</h5>
														<p className='card-text p-3'>{t('home.safetyPrivacysub')}</p>
													</div>
												</div>
											</div>
										</Col>
									</Row>
								</SwiperSlide>
								<SwiperSlide>
									<Row className='justify-content-center  align-items-center '>
										<Col md={6} xs={6} className='text-center mx-auto'>
											<div className='card text-center' style={{
												display: 'flex',
												flexDirection: 'column', justifyContent: 'space-between',
												gap: '30px'
											}}>
												<div className='card-header'>
													<img
														src='/img/Group 5213.png'
														className='img-fluid mblimg'
														alt='grp'
														style={{
															marginTop: '-38px', display: 'inline',
															height: '100%'
														}}></img>
												</div>
												<div className='card-body' style={{ marginTop: '-43px' }}>
													<h5 className='card-title' style={{ color: '#5448B2' }}>
														{t('home.Languages')}
													</h5>
													<p className='card-text p-3'>{t('home.Languagessub')}</p>
												</div>
											</div>
										</Col>
										<Col md={6} xs={6} className='text-center mx-auto'>
											<div className='card text-center' style={{
												display: 'flex',
												flexDirection: 'column', justifyContent: 'space-between',
												gap: '30px'
											}}>
												<div className='card-header'>
													<img
														src='/img/Group 5209.png'
														className='img-fluid mblimg'
														alt='grp'
														style={{
															marginTop: '-38px', display: 'inline',
															height: '100%'
														}}></img>
												</div>
												<div className='card-body' style={{ marginTop: '-43px' }}>
													<div class='card-block'>
														<h5 className='card-title' style={{ color: '#4280D8' }}>
															{t('home.machineLearning')}
														</h5>
														<p className='card-text p-3'>{t('home.machineLearningsub')}</p>
													</div>
												</div>
											</div>
										</Col>
									</Row>
								</SwiperSlide>
							</Swiper>
						</Container>
						<br></br>
					</div>
				</div>

				{window.location.pathname === '/discover' && (
					<Footerr setLanguage={props.setLanguage} language={props.language} />
				)}
			</div>
		</>
	);
}

export default Discover;
