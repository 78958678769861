import "./Footer.css";

import cookies from "js-cookie";
import { Col, Container, Footer, Row } from "mdbreact";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { animateScroll as scroll } from "react-scroll";

import logoFooter from "../../assets/seezittLogoLightFooter.png";
import { Link } from "../../components/Navbar/NavbarElements";

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    dir: "rtl",
    country_code: "sa",
  },
  {
    code: "fr",
    name: "French",
    country_code: "fr",
  },
];

export default function Footert(toggle) {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t, i18n } = useTranslation();
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  useEffect(() => {
    console.log("SeezItt");
    document.body.dir = (currentLanguage && currentLanguage.dir) || "ltr";
    document.title = t("Seezitt");
  }, [currentLanguage, t]);
  return (
    <div style={{ fontFamily: i18n.language === "ar" ? "Tajawal" : "Lexend" }}>
      <section className="flex flex-col  mx-auto bg  ">
        <Footer className="mb-4 mt-5">
          <Container>
            <Row>
              <Col md={3} className="">
                <Link to="/">
                  <img
                    src={logoFooter}
                    alt="logo"
                    className="h-[60px] footlogo"
                    to="/"
                    onClick={toggleHome}
                  />{" "}
                </Link>
              </Col>

              <Col>
                <h5 className="footerhead">{t("home.about")}</h5>
                <ul className="list-unstyled footersub">
                  <li id="">
                    <Link
                      to="/"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                      className="footersub"
                    >
                      {t("home.features")}{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                      className="footersub"
                    >
                      {t("home.discovertitle")}{" "}
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                      className="footersub"
                    >
                      {t("home.contactUs")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: "/",
                        hash: "DownloadApp",
                      }}
                      onClick={toggle}
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                      className="footersub"
                    >
                      {t("home.downloadApp")}
                    </Link>
                  </li>
                </ul>
              </Col>

              <Col>
                <h5 className=" footerhead ">{t("home.support")}</h5>
                <ul className="list-unstyled footersub">
                  {/*    <li><a href="#!" className="footersub">FAQs</a></li> */}
                  <li>
                    <Link to="/Faqs" className="footersub">
                      {t("home.faqs")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy" className="footersub">
                      {t("home.privacy")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms-conditions" className="footersub">
                      {t("home.Terms")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/community-guidelines" className="footersub">
                      {t("home.guidelines")}{" "}
                    </Link>
                  </li>
                </ul>
              </Col>

              <Col>
                <div className="">
                  <div className="mb-2 footerhead">{t("home.followus")}</div>

                  <div className="flex flex-row">
                    {/* 
                    <div className="icons ">
                      <a href="https://instagram.com">
                        <FaInstagram
                          size={30}
                          style={{
                            color: "#383838",
                            padding: "5px",
                          }}
                          className="social"
                        />
                      </a>
                      &nbsp;
                      <a href="https://twitter.com">
                        <FaTwitter
                          size={30}
                          style={{
                            color: "#383838",
                            padding: "5px",
                          }}
                          className="social"
                        />
                      </a>
                      &nbsp;
                      <a href="https://www.linkedin.com/showcase/seezitt/">
                        <FaLinkedinIn
                          size={30}
                          style={{
                            color: "#383838",
                            padding: "5px",
                          }}
                          className="social"
                        />
                      </a>
                      &nbsp;
                      <a href="https://www.facebook.com/people/SeezItt/100088846144498/">
                        <FaFacebookF
                          size={30}
                          style={{
                            color: "#383838",
                            padding: "5px",
                          }}
                          className="social"
                        />
                      </a>
                    </div>
                    */}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          <hr className="" />

          <div className="py-3 flex felx-row   ">
            <div className="text-center  atcopyright">
              &copy; {new Date().getFullYear()} SeezItt{" "}
              {/* <a href="javascript:void(0)"> </a> */}
            </div>
          </div>
        </Footer>
      </section>
    </div>
  );
}
