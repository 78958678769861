const translations = {
  "home.about": "حول",
  "home.contactUs": "اتصل بنا",
  test: "تستااات",
  "home.faqs": "الأسئلة المتكررة",
  "home.features": "الميزات",
  "home.discover": "إكتشف",
  "home.downloadApp": "ابدأ الاستخدام",
  "home.heroHead": "منصة مفعمة بالفرح والمتعة لإسعاد الناس.",
  "home.heroSubhead":
    "أنشئ مقاطع فيديو قصيرة لإبراز مواهبك والتواصل مع الأصدقاء ورسم الابتسامة على وجوههم.",
  "home.title": "عنا",
  "home.aboutHeadder": "أطلق العنان لإبداعك",
  "home.aboutSubheadder":
    "يوفر SeezItt مساحة إبداعية آمنة لمشاركة مقاطع الفيديو القصيرة الخاصة بك، واستلهام أفكار مميزة لعرض مهاراتك سواء كانت وصفة عشاء جديدة أو كيفية اختيار إطلالة أنيقة.",
  "home.aboutDownload": "قم بتنزيله الآن",
  "home.featuresTitle": "الميزات",
  "home.videoEnhancementhedder": "جودة فيديو محسنة",
  "home.videoEnhancementsub":
    "قم بتسجيل وتحميل مقاطع فيديو قصيرة ومقاطع الريلز لمدة 15 ثانية باستخدام أداة تعديل الفيديو المجانية سهلة الاستخدام لزيادة تفاعل الجمهور.",
  "home.homeFeedheadder": "صانع المحتوى",
  "home.homeFeedSub":
    "أنشئ عدد غير محدود من مقاطع الفيديو وكن صانعًا للترند، أو ابحث عن مقاطع الفيديو المشهورة وشاركها.",
  "home.photoLayoutheadder": "عبّر عن نفسك، تخيل إمكانيات لا حدود لها",
  "home.photoLayoutheaddersub":
    " عبّر عن نفسك وتخيل إمكانيات لا حدود لها مع تطبيق يسمح لك بتعديل مقاطع الفيديو القصيرة، لتحويل حلمك وفكرتك إلى الواقع.",
  "home.categorySelectionheadder": "تابع موضوعاتك المفضلة",
  "home.categorySelectionsub":
    "نقدم لك مجموعة متنوعة من المحتوى الذي يلائم اهتماماتك الخاصة، من خلال عملية اختيار وتنظيم دقيقة.",
  "home.connectMemoriesheadder": "تواصل مع أصدقائك",
  "home.connectMemoriessub":
    "أرسل لأصدقائك القدامى مقاطع الفيديو اللطيفة والمسلية وعاود التواصل معهم.",
  "home.discovertitle": "اكتشف",
  "home.ourGiftsKit": "مجموعة الهدايا الخاصة بنا",
  "home.ourGiftsKitsub":
    "قم بتشجيع ودعم الحسابات المفضلة لديك عن طريق إرسال الهدايا الافتراضية لهم..",
  "home.caesiumCoins": "عملات السيزيوم",
  "home.caesiumCoinssub":
    "يمكنك كسب أموال حقيقية وإهدائها باستخدام عملتنا الافتراضية، السيزيوم.",
  "home.standOut": "تألق",
  "home.standOutsub":
    "كن مؤثرًا وألهم الأخرين من خلال إنشاء محتوى إبداعي يحمل طابعك الخاص.",
  "home.Connect": "تواصل",
  "home.Connectsub":
    "امنح علامتك التجارية فرصة للتطور والنجاح وسط مجتمع عالمي متنوع.",
  "home.events": "الأحداث",
  "home.eventssub":
    "شارك في الأحداث والمسابقات واحصل على مكافآت كلما تفاعلت أكثر مع المجتمع.",
  "home.safetyPrivacy": "الأمان والخصوصية",
  "home.safetyPrivacysub":
    "خصوصيتك تهمنا، لذلك نوفر لك أفضل معايير الأمان والخصوصية لحمايتك.",
  "home.Languages": "اللغات",
  "home.Languagessub":
    "يدعم تطبيقنا اللغة الإنجليزية والعربية والعديد من اللغات الأخرى.",
  "home.machineLearning": "التعلم الالي",
  "home.machineLearningsub":
    "نهتم بمعرفة وجمع نمط المحتوى الذي يتماشى مع اهتماماتك.",
  "home.contactUS": "تواصل معنا",
  "home.contactUSsub": "كن على تواصل",
  "home.contactUSsubheadder1": "يسعد فريقنا تواصلكم معنا وإعادة الاتصال بكم.",
  "home.contactUSsubheadder2": "تواصل معنا وقتما تشاء، رأيك يهمنا للغاية.",
  "home.sendmessage": "أرسل رسالة",
  "home.name": "الاسم",
  "home.email": "عنوان البريد الالكترونى",
  "home.yourmessage": "رسالتك",
  "home.contactusfooter": "لا تدع الفرصة تفوتك. قم بالتنزيل الآن",
  "home.support": "الدعم",
  "home.privacy": "سياسة الخصوصية",
  "home.Terms": "الأحكام والشروط",
  "home.guidelines": "إرشادات المجتمع",
  "home.followus": "تابعنا على",
  "home.apple": "تنزيل من ملف",
  "home.google": "احصل عليه",
  "home.namefeedback": "مطلوب اسم!",
  "home.emailfeedBack1": "البريد الالكتروني مطلوب!",
  "home.emailfeedBack2": "هذا ليس تنسيق بريد إلكتروني صالح!",
  "home.messagefeedBack1": "الرسالة مطلوبة",
  "home.messagefeedBack2": "يجب أن تكون الرسالة أكثر من 10 أحرف",
  "home.messagefeedBack3": "لا يمكن أن تتجاوز الرسالة 15 حرفًا",
};

export default translations;
