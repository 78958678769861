import './Footer.css';

import cookies from 'js-cookie';
import { Col, Container, Footer, Row } from 'mdbreact';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

import logoFooter from '../../assets/seezittLogoLightFooter.png';

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    dir: "rtl",
    country_code: "sa",
  },
  {
    code: "fr",
    name: "French",
    country_code: "fr",
  },
];

export default function Footert(toggle) {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t, i18n } = useTranslation();
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  useEffect(() => {
    console.log("SeezItt");
    document.body.dir = (currentLanguage && currentLanguage.dir) || "ltr";
    document.title = t("Seezitt");
  }, [currentLanguage, t]);

  return (
    <div style={{ fontFamily: i18n.language === "ar" ? "Tajawal" : "Lexend" }}>
      <section className="flex flex-col  mx-auto ">
        <Footer className="footer bg ">
          <Container className="footerContainer">
            <Row className="">
              <Col md={3} className="">
                <Link to="/">
                  <img
                    src={logoFooter}
                    className="smallLogo"
                    alt="logo"
                    to="/"
                    onClick={toggleHome}
                  />{" "}
                </Link>
              </Col>
              <Col>
                <h5 className="footerhead">{t("home.about")}</h5>
                <ul className="list-unstyled footersub">
                  <li id="">
                    <Link
                      to="/#Feature"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                      className="footersub"
                    >
                      {t("home.features")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/#Discover"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                      className="footersub"
                    >
                      {t("home.discovertitle")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/#ContactUs"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                      className="footersub"
                    >
                      {t("home.contactUs")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/#Download"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                      className="footersub"
                    >
                      {t("home.downloadApp")}
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col>
                <h5 className=" footerhead ">{t("home.support")}</h5>
                <ul className="list-unstyled footersub">
                  {/*    <li><a href="#!" className="footersub">FAQs</a></li> */}
                  <li>
                    <Link to="/faqs" className="footersub">
                      {t("home.faqs")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy" className="footersub">
                      {t("home.privacy")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms-conditions" className="footersub">
                      {t("home.Terms")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/community-guidelines" className="footersub">
                      {t("home.guidelines")}
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col>
                <div className="">
                  {/* <div className="mb-2 footerhead">{t("home.followus")} :</div> */}
                  {/* <div className="flex flex-row"> */}
                  {/*
                    <div className="icons ">
                      <a href="https://instagram.com">
                        <svg
                          width="35"
                          height="32"
                          viewBox="0 0 35 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <ellipse
                            cx="17.3"
                            cy="16"
                            rx="16.8"
                            ry="16"
                            fill="white"
                          />
                          <path
                            d="M17.0017 11.6542C14.8734 11.6542 13.1567 13.3712 13.1567 15.5C13.1567 17.6288 14.8734 19.3458 17.0017 19.3458C19.1299 19.3458 20.8466 17.6288 20.8466 15.5C20.8466 13.3712 19.1299 11.6542 17.0017 11.6542ZM17.0017 18.0003C15.6263 18.0003 14.502 16.879 14.502 15.5C14.502 14.121 15.623 12.9997 17.0017 12.9997C18.3804 12.9997 19.5014 14.121 19.5014 15.5C19.5014 16.879 18.377 18.0003 17.0017 18.0003ZM21.9007 11.4969C21.9007 11.9956 21.4992 12.3939 21.0039 12.3939C20.5053 12.3939 20.1071 11.9922 20.1071 11.4969C20.1071 11.0015 20.5086 10.5999 21.0039 10.5999C21.4992 10.5999 21.9007 11.0015 21.9007 11.4969ZM24.4473 12.4073C24.3904 11.2057 24.116 10.1413 23.2359 9.26436C22.3592 8.38743 21.295 8.11296 20.0937 8.05272C18.8555 7.98243 15.1445 7.98243 13.9063 8.05272C12.7083 8.10962 11.6442 8.38408 10.7641 9.26102C9.88399 10.138 9.61294 11.2023 9.5527 12.4039C9.48243 13.6424 9.48243 17.3543 9.5527 18.5927C9.60959 19.7943 9.88399 20.8587 10.7641 21.7356C11.6442 22.6126 12.705 22.887 13.9063 22.9473C15.1445 23.0176 18.8555 23.0176 20.0937 22.9473C21.295 22.8904 22.3592 22.6159 23.2359 21.7356C24.1127 20.8587 24.3871 19.7943 24.4473 18.5927C24.5176 17.3543 24.5176 13.6457 24.4473 12.4073ZM22.8477 19.9215C22.5867 20.5775 22.0814 21.083 21.4222 21.3474C20.435 21.739 18.0926 21.6486 17.0017 21.6486C15.9108 21.6486 13.565 21.7356 12.5811 21.3474C11.9253 21.0863 11.42 20.5809 11.1556 19.9215C10.7641 18.9341 10.8544 16.5912 10.8544 15.5C10.8544 14.4088 10.7674 12.0625 11.1556 11.0785C11.4166 10.4225 11.9219 9.91705 12.5811 9.65263C13.5683 9.26102 15.9108 9.35139 17.0017 9.35139C18.0926 9.35139 20.4384 9.26436 21.4222 9.65263C22.0781 9.9137 22.5834 10.4191 22.8477 11.0785C23.2393 12.0659 23.1489 14.4088 23.1489 15.5C23.1489 16.5912 23.2393 18.9375 22.8477 19.9215Z"
                            fill="#383838"
                            stroke="#383838"
                            stroke-width="0.5"
                          />
                        </svg>
                      </a>
                      &nbsp;
                      <a href="https://twitter.com">
                        <svg
                          width="35"
                          height="32"
                          viewBox="0 0 35 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <ellipse
                            cx="17.7"
                            cy="16"
                            rx="16.8"
                            ry="16"
                            fill="white"
                          />
                          <path
                            d="M26.0998 10.5859C25.4751 10.8469 24.8094 11.0199 24.1153 11.1039C24.8293 10.6979 25.3743 10.0599 25.6305 9.29086C24.9648 9.66886 24.2298 9.93586 23.4465 10.0849C22.8144 9.44386 21.9135 9.04686 20.9307 9.04686C19.0239 9.04686 17.4888 10.5209 17.4888 12.3279C17.4888 12.5879 17.5119 12.8379 17.5686 13.0759C14.7052 12.9429 12.1716 11.6359 10.4695 9.64486C10.1724 10.1359 9.99805 10.6979 9.99805 11.3029C9.99805 12.4389 10.6123 13.4459 11.5279 14.0289C10.9746 14.0189 10.4317 13.8659 9.9718 13.6249C9.9718 13.6349 9.9718 13.6479 9.9718 13.6609C9.9718 15.2549 11.1657 16.5789 12.7312 16.8839C12.4509 16.9569 12.1453 16.9919 11.8282 16.9919C11.6077 16.9919 11.3851 16.9799 11.1762 16.9359C11.6224 18.2349 12.8887 19.1899 14.3944 19.2209C13.2226 20.0939 11.7348 20.6199 10.1241 20.6199C9.8416 20.6199 9.5707 20.6079 9.2998 20.5749C10.8255 21.5119 12.6336 22.0469 14.5834 22.0469C20.9212 22.0469 24.3862 17.0469 24.3862 12.7129C24.3862 12.5679 24.381 12.4279 24.3736 12.2889C25.0572 11.8269 25.6315 11.2499 26.0998 10.5859Z"
                            fill="#383838"
                          />
                        </svg>
                      </a>
                      &nbsp;
                      <a href="https://www.linkedin.com/showcase/seezitt/">
                        <svg
                          width="34"
                          height="32"
                          viewBox="0 0 34 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <ellipse
                            cx="17.1"
                            cy="16"
                            rx="16.8"
                            ry="16"
                            fill="white"
                          />
                          <path
                            d="M12.4566 12.5469H8.69971V23.5469H12.4566V12.5469Z"
                            fill="#383838"
                          />
                          <path
                            d="M22.6899 12.6759C22.65 12.6639 22.6122 12.6509 22.5702 12.6399C22.5198 12.6289 22.4694 12.6199 22.418 12.6119C22.2185 12.5739 22.0001 12.5469 21.7439 12.5469C19.5536 12.5469 18.1644 14.0639 17.7066 14.6499V12.5469H13.9497V23.5469H17.7066V17.5469C17.7066 17.5469 20.5458 13.7809 21.7439 16.5469C21.7439 19.0159 21.7439 23.5469 21.7439 23.5469H25.4997V16.1239C25.4997 14.4619 24.3038 13.0769 22.6899 12.6759Z"
                            fill="#383838"
                          />
                          <path
                            d="M10.5372 11.0469C11.552 11.0469 12.3747 10.2634 12.3747 9.29688C12.3747 8.33038 11.552 7.54688 10.5372 7.54688C9.52238 7.54688 8.69971 8.33038 8.69971 9.29688C8.69971 10.2634 9.52238 11.0469 10.5372 11.0469Z"
                            fill="#383838"
                          />
                        </svg>
                      </a>
                      &nbsp;
                      <a href="https://www.facebook.com/people/SeezItt/100088846144498/">
                        <svg
                          width="35"
                          height="32"
                          viewBox="0 0 35 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <ellipse
                            cx="17.5"
                            cy="16"
                            rx="16.8"
                            ry="16"
                            fill="white"
                          />
                          <path
                            d="M20.2977 10.2035H21.8314V7.65953C21.5668 7.62487 20.6568 7.54687 19.597 7.54687C17.3857 7.54687 15.8709 8.87153 15.8709 11.3062V13.5469H13.4307V16.3909H15.8709V23.5469H18.8627V16.3915H21.2042L21.5759 13.5475H18.862V11.5882C18.8627 10.7662 19.0951 10.2035 20.2977 10.2035Z"
                            fill="#383838"
                          />
                        </svg>
                      </a>
                    </div>
                  */}
                </div>
                {/* </div> */}
              </Col>
            </Row>
          </Container>
          <hr
            className="footerSeparator"
            style={{ marginTop: "10px", marginBottom: "10px" }}
          />
          <div className="py-3 flex felx-row bg justify-center">
            <div
              className="text-center  atcopyright"
              style={{ textAlign: "center" }}
            >
              &copy; {new Date().getFullYear()} Seezitt
            </div>
          </div>
        </Footer>
      </section>
    </div>
  );
}
