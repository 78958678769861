import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { MdArrowBack } from "react-icons/md";
import { Link } from "react-scroll";
import styled from "styled-components";

export const Button = styled(Link)`
  border-radius: 50px;
  background: ${({ primary }) => (primary ? "#5D41DE" : "#5D41DE")};
  white-space: nowrap;
  height: 59px;
  width: 220px;
 
  
  color: ${({ dark }) => (dark ? "#fff" : "#fff")};
  font-size:${({ fontBig }) => (fontBig ? "16px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  text-decoration:none;
/*   font-family: 'Lexend'; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  align-items: center;
  text-align: center;
  
  color: #FFFFFF;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: linear-gradient(88.28deg, #A1C0EC 13.02%, #5D41DE 95.67%);
color:#fff;

width: 200px;


  }

  @media screen and (max-width: 768px) {
    height: 36px;
    width: 170px;
  
  border-radius: 101.5px;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: linear-gradient(88.28deg, #A1C0EC 13.02%, #5D41DE 95.67%);
color:#fff;

width: 150px;


  
  }
  @media screen and (max-width: 480px) {
    height: 36px;
  width: 170px;
  
  border-radius: 101.5px;
  
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: linear-gradient(88.28deg, #A1C0EC 13.02%, #5D41DE 95.67%);
color:#fff;

width: 150px;

  
  }
 
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 35px;
  @media screen and (max-width: 480px) {
    margin-top: 5px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 5px;
  }
`;
export const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  @media screen and (max-width: 991px) {
    padding-right: 0px;
    padding-left: 0px;
    margin-top: -65px;
  }
`;
export const ArrowForward = styled(BsArrowLeft)`
  margin-left: 0px;
  font-size: 0px;
  z-index: -1;
`;

export const ArrowBack = styled(MdArrowBack)`
  margin-left: 15px;

  font-size: 28px;
  width: 48px;

  border-radius: 0px;
`;
export const ArrowRight = styled(BsArrowRight)`
  margin-left: 0px;
  margin-right: 0px;

  border-radius: 0px;
  text-align: end;
`;
