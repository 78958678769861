import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  min-height: 692px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  overflow: hidden;
  
`
export const FormWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 480px) {
    height: 80%;
  }
`
export const Icon = styled(Link)`
  margin-left: 32px;
  margin-top: 32px;
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  font-size: 32px;

  @media screen and (max-width: 480px) {
    margin-left: 16px;
    margin-top: 8px;
  }
`
export const FormContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 480px) {
    padding: 10px;
  }
`
export const Form = styled.form`
  background-color: #fff;
  max-width: 1240px;
  height: auto;
  min-height: 637px;
  width: 100%;
  z-index: 1;
  display: grid;
  grid-template-columns: '1fr 1fr'
  margin: 0 auto;
  border-radius: 60px;
  box-shadow: 0px -3px 10px 0px #0000001A;


  @media screen and (max-width: 786px) {
    padding: 16px 20px;
    margin: -100px auto;
    border-radius: 30px;
  }
  `
export const FormH1 = styled.h1`
  margin-bottom: 40px;
/*   font-family: 'Lexend'; */
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 44px;
 
  
  text-align: center;
  
  color: #2A2A2A;
  
  text-align: center;
  @media screen and (max-width: 480px) {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 22px
    margin-bottom: 10px;
  }

`
export const FormLabel = styled.label`
  margin-bottom: 8px;
  font-size: 14px;
  color: #000;
`



export const FormTextArea = styled.textarea`
padding: 16px 16px;
margin-bottom: 32px;
border-radius: 15px;
border: 1px solid #C4C4C4;
/* font-family: 'Lexend'; */
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 19px;
color: #666666;
@media screen and (max-width: 480px) {
  font-size: 10px;
  line-height: 12px;
  border-radius: 7px;
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  font-size: 10px;
  line-height: 12px;
  border-radius: 7px;
  margin-bottom: 15px;
}

`
export const FormInput = styled.input`
  padding: 16px 16px;
  border: 1px solid #C4C4C4;
  margin-bottom: 32px;
  border-radius: 15px;

font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 19px;
color: #666666;
@media screen and (max-width: 480px) {
  font-size: 10px;
  line-height: 12px;
  border-radius: 7px;
  margin-bottom: 15px;
  padding: 12px 12px;
}
@media screen and (max-width: 768px) {
  font-size: 10px;
  line-height: 12px;
  border-radius: 7px;
  margin-bottom: 15px;
  padding: 12px 12px;
}

`
export const FormButton = styled.button`
  background:  #5448B2;
  padding: 16px 0;
  border: none;
  border-radius: 15px;
  cursor: pointer;
/*   font-family: 'Lexend'; */
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;

align-items: center;
text-align: center;

color: #FFFFFF;

@media screen and (max-width: 480px) {
  font-size: 12px;
  line-height: 15px;

}
@media screen and (max-width: 768px) {
  font-size: 12px;
  line-height: 15px;

}
`
export const Text = styled.span`
text-align: center;
margin-top: 24px;
color: #fff;
font-size: 14px;
`
