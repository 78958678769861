import "tailwindcss/tailwind.css";
import "./App.css";

import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import messages from "../src/languages-intl";
import About from "./components/About/About";
import Comunity from "./components/ComunityGuidelines/Comunity";
import Contactus from "./components/Contactus/Contactus";
import Discover from "./components/Discover/Discover";
import Faqs from "./components/Faqs/Faqs";
import Features from "./components/Feature/Feature";
import Footert from "./components/footerr/Footer";
import Hero from "./components/Hero";
import PrivacyPolicy from "./components/Privacy/PrivacyPolicy";
import ScrollToTop from "./components/ScrollToTop";
import Terms from "./components/TermsCondition/Terms";
import Home from "./pages";

function App() {
  //   const [isOpen, setIsOpen] = useState(false);

  const [appLanguage, setAppLanguage] = useState(window.localStorage.getItem("lang") || "en");
  const setLanguage = (language) => {
    setAppLanguage(language);
    window.localStorage.setItem("lang", language);
  };

  return (
    <IntlProvider locale={appLanguage} messages={messages[appLanguage]}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home setLanguage={setLanguage} language={appLanguage} />} />
          <Route exact path="/hero" element={<Hero setLanguage={setLanguage} language={appLanguage} />} />
          <Route exact path="/about" element={<About setLanguage={setLanguage} language={appLanguage} />} />
          <Route exact path="/features" element={<Features setLanguage={setLanguage} language={appLanguage} />} />
          <Route exact path="/discover" element={<Discover setLanguage={setLanguage} language={appLanguage} />} />
          <Route exact path="/Contactus" element={<Contactus setLanguage={setLanguage} language={appLanguage} />} />
          <Route path="/Faqs" element={<Faqs setLanguage={setLanguage} language={appLanguage} />} />
          <Route exact path="/Footert" element={<Footert setLanguage={setLanguage} language={appLanguage} />} />
          {/* <Route exact path="/Footert" element={<Footert setLanguage={setLanguage} language={appLanguage} />} /> */}
          <Route exact path="/privacy-policy" element={<PrivacyPolicy setLanguage={setLanguage} language={appLanguage} />} />
          <Route exact path="/terms-conditions" element={<Terms setLanguage={setLanguage} language={appLanguage} />} />
          <Route exact path="/community-guidelines" element={<Comunity setLanguage={setLanguage} language={appLanguage} />} />
        </Routes>
      </BrowserRouter>
    </IntlProvider>
  );
}

export default App;
