import 'tailwindcss/tailwind.css';
// Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
// Bootstrap Bundle JS
import 'bootstrap/dist/js/bootstrap.bundle.min';

import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { initReactI18next } from 'react-i18next';
import App from './App';

i18next
	.use(HttpApi)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		supportedLngs: ['en', 'ar', 'fr'],
		fallbackLng: 'en',
		debug: false,
		// Options for language detector
		detection: {
			order: ['path', 'cookie', 'htmlTag'],
			caches: ['cookie'],
		},
		// react: { useSuspense: false },
		backend: {
			loadPath: '/assets/locales/{{lng}}/translation.json',
		},
	});
const loadingMarkup = (
	<div className='py-4 text-center'>
		<h3>Loading..</h3>
	</div>
);

ReactDOM.render(
	<Suspense fallback={loadingMarkup}>
			<App />
	</Suspense>,
	document.getElementById('root')
);
