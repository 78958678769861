import cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import mobileImg from '../../assets/mobileImg.png';
import { ArrowRight, BtnWrap, Button, Container } from '../ButtonElements';
import { LineImage } from '../Hero/HeroElements';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../SideBar';
import Footerr from '../footerr/Footer';
import './About.css';

const languages = [
	{
		code: 'en',
		name: 'English',
		country_code: 'gb',
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl',
		country_code: 'sa',
	},
	{
		code: "fr",
		name: "French",
		country_code: "fr",
	},
];

function About(props) {
	const currentLanguageCode = cookies.get('i18next') || 'en';
	const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
	const { t, i18n } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const [hover, setHover] = useState(false);

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	const scrollToBottom = () => {
		window.scrollTo({
			top: document.documentElement.scrollHeight,
			behavior: 'auto',
			/* you can also use 'auto' behaviour 
		 in place of 'smooth' */
		});
	};

	useEffect(() => {
		console.log('SeezItt');
		document.body.dir = (currentLanguage && currentLanguage.dir) || "ltr";
		document.title = t('Seezitt');
	}, [currentLanguage, t]);


	useEffect(() => {
		const observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				const square = entry.target.querySelector('.mbl-img');

				if (square) {
					if (entry.isIntersecting) {
						square.classList.add('mbl-img-animation');
					} else {
						square.classList.remove('mbl-img-animation');
					}
				} else {
					console.log("Element with class 'mbl-img' not found.");
				}
			});
		});

		const element = document.querySelector('.imagePlusDescription');
		if (element) {
			observer.observe(element);
		} else {
			console.log("Element with class 'mbl-img' not found.");
		}

		return () => {
			observer.disconnect();
		};
	}, []);


	return (
		<>
			<div style={{ fontFamily: i18n.language === 'ar' ? 'Tajawal' : 'Lexend' }}>
				{window.location.pathname === '/about' && <Sidebar isOpen={isOpen} toggle={toggle} /> && (
					<Navbar toggle={toggle} setLanguage={props.setLanguage} language={props.language} />
				)}

				<div id='About'>
					<LineImage src='/img/line.png' className='mb-4'></LineImage>
					<div className='About-container aboutbg'>
						<Container className='gap-0'>
							<Row className='mainContainer'>
								<Col className='col-lg-8 col-md-8 col-sm-8 col-xs-8 text-center '>
									<img
										src='/img/Vector (2).png'
										alt=''
										className='Vector2'
										style={{ marginTop: '-180px', position: 'relative' }}
									/>
								</Col>

								<Col className='col-lg-2 col-md-2 col-sm-2 col-xs-2 text-center'>
									<img
										src='/img/diamond3.png'
										alt=''
										style={{ marginTop: '-80px', position: 'relative' }}
										className='diamond3'
									/>
								</Col>

								<Col className='col-lg-2 col-md-2 col-sm-2 col-xs-2 text-center'>
									<img src='/img/Group (3).png' alt='' className='Group3' />
								</Col>
							</Row>

							<Row className='imagePlusDescription'>
								<Col className={currentLanguageCode === 'ar' ? 'mobileImgColAr' : 'mobileImgCol'}>
									<div className='mbl-img'>
										<img src={mobileImg} className='img-fluid BlueMbl' alt='' />
									</div>
								</Col>

								<Col className='px-5 about-section'>
									<div className='mb-4 about-containt'>
										<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
											<h6 className='title mb-4'>{t('home.title')}</h6>
											<h2 className='aboutHeadder mb-4 font-semibold'> {t('home.aboutHeadder')}</h2>
											<p className={currentLanguageCode === 'ar' ? 'mb-4 aboutSubheadderAr' : 'mb-4 aboutSubheadder'} style={{ marginLeft: '0' }}>{t('home.aboutSubheadder')}</p>
											<BtnWrap>
												<Button
													onClick={scrollToBottom}
													to={{
														pathname: '/',
														hash: 'DownloadApp',
													}}
													onMouseEnter={() => setHover(true)}
													onMouseLeave={() => setHover(false)}
													primary='true'
													dark='true'>
													{t('home.aboutDownload')}&nbsp;&nbsp;
													{hover && <ArrowRight size={35} />}
												</Button>
											</BtnWrap>
											<br></br>
										</div>
										<img
											src='/img/diamond3.png'
											alt=''
											className='diamond2'
										// style={{ marginLeft: '96px', marginRight: '96px' }}
										/>
									</div>
								</Col>

								<Row>
									<Col lg={6} className='px-20'>
										<div className=''>
											<img src='/img/Group (3).png' alt='' style={{ marginTop: '0px' }} className='Group2' />
										</div>
									</Col>
								</Row>
							</Row>
						</Container>
					</div>


				</div>
				{window.location.pathname === '/about' && <Footerr />}
			</div>
		</>
	);
}

export default About;
